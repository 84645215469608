//React
import React from "react"

const Snippet = props => (
  <div className="row d-flex align-items-center justify-content-center fade-in">
    <div className="col-6 pt-4 px-4 d-flex align-items-center justify-content-center">
      <div className="progress" style={{ width: "100%" }}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-success"
          role="progressbar"
          aria-valuenow={`${props.fileloaded}`}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${props.fileloaded}%`,
          }}
        ></div>
      </div>
    </div>
  </div>
)

export default Snippet
