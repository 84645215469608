//React
import React from "react"
import { connect } from "react-redux"

//Constants
import {
  surveySample1,
  criteriaSample1,
  surveySample1Counter,
  criteriaSample1Counter,
} from "../../utils/surveyConstants"

//Actions
import { updateScreenerForm } from "../../actions/createScreener"

//Redux
const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val, mode) =>
    dispatch(updateScreenerForm(prop, val, mode)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  loadSampleScreener() {
    this.props.updateScreenerForm("survey", surveySample1)
    this.props.updateScreenerForm("criteria", criteriaSample1)
    this.props.updateScreenerForm("criteriacounter", criteriaSample1Counter)
    this.props.updateScreenerForm("surveycounter", surveySample1Counter)
  }

  render() {
    return (
      <div
        class="modal fade"
        id="loadSampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loadSampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            {/* <div class="modal-header">
              <h5 class="modal-title" id="loadSampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div class="modal-body p-4">
              <p class="black-4 text-center display-4">
                This will replace any current screener questions and evaluation
                criteria.
              </p>
              <p class="h5 black-4 text-center">Do you want to continue?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                onClick={(e) => {
                  this.loadSampleScreener()
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(Snippet)
