//React
import React from "react"
import { connect } from "react-redux"

//Packages
import { TwitterPicker } from "react-color"
import parse from "url-parse"
import { v4 as uuidv4 } from "uuid"

//Actions
import {
  updateScreenerForm,
  updateLandingdataOption,
  updateScreenerFieldDirect,
} from "../../actions/createScreener"

//Components
import Loading from "../loading"

//Icons
import { FaCheck, FaTimesCircle } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  templatekeyword,
  templatecreate,
  isLoadingTemplateCheck,
}) => {
  return { templatekeyword, templatecreate, isLoadingTemplateCheck }
}

const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val, mode) =>
    dispatch(updateScreenerForm(prop, val, mode)),
  updateLandingdataOption: (prop, val, lang) =>
    dispatch(updateLandingdataOption(prop, val, lang)),
  updateScreenerFieldDirect: (group, prop, val) =>
    dispatch(updateScreenerFieldDirect(group, prop, val)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const url = parse(window.location.href, true)

    if (!url.query.t) {
      this.updateLandingdataOption("uploadtext", "Health questionnaire", "en")
      this.updateLandingdataOption(
        "uploadsubtext",
        "Please fill out the short health questionnaire below",
        "en"
      )
    }
  }

  insertDensity(imageKey, density) {
    const revisedFilename =
      imageKey.substring(0, imageKey.lastIndexOf(".")) +
      `@${density}` +
      imageKey.substring(imageKey.lastIndexOf("."))
    return revisedFilename
  }

  handleUpdateColor = (event) => {
    console.log("handleUpdateColor - event")
    console.log(event.hex)
    this.props.updateScreenerFieldDirect("landing", "uploadbg", event.hex)
  }
  updateLandingdataOption = (prop, val, lang) => {
    this.props.updateLandingdataOption(prop, val, lang)
  }
  updateScreenerFieldDirect = (group, prop, val) => {
    this.props.updateScreenerFieldDirect(group, prop, val)
  }

  handleUpdate = (event) => {
    console.log("handleUpdate - event")
    console.log(event)
    if (event.target.name === "keyword") {
      this.props.updateScreenerForm(
        event.target.name,
        event.target.value.toLowerCase()
      )
      return
    } else if (event.target.name === "logoUpload") {
      console.log("handleUpdate - event.target.files[0]")
      console.log(event.target.files[0])

      let that = this
      let reader = new FileReader()
      const fileTypes = ["jpg", "jpeg", "png", "gif"] //acceptable file types

      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0]
      ) {
        const fileExtension = event.target.files[0].name
          .split(".")
          .pop()
          .toLowerCase()

        if (event.target.files[0].size > 4000000) {
          this.updateScreenerFieldDirect(
            "error",
            "imagesize",
            "Please upload an image less than 4 MB"
          )
        } else if (fileTypes.indexOf(fileExtension) <= -1) {
          this.updateScreenerFieldDirect(
            "error",
            "imageextension",
            "Please upload a JPEG, PNG or GIF image"
          )
        } else {
          this.updateScreenerFieldDirect("error", "imagesize", false)
          this.updateScreenerFieldDirect("error", "imageextension", false)
          reader.readAsDataURL(event.target.files[0]) // converts the file to base64 and calls onload
        }

        reader.onload = function () {
          const img = new Image()
          img.addEventListener("load", () => {
            console.log("*****img")
            console.log(img.width)
            that.props.updateScreenerFieldDirect(
              "screener",
              "logowidth",
              img.width
            )
            if (img.width > 5000) {
              that.updateScreenerFieldDirect(
                "error",
                "imagewidth",
                "Please upload an image less than 5000 pixels in width"
              )
            } else {
              that.updateScreenerFieldDirect("error", "imagewidth", false)
            }
          })
          img.src = reader.result

          that.props.updateScreenerFieldDirect(
            "screener",
            "logo",
            reader.result
          )
          that.props.updateScreenerFieldDirect("screener", "logoexist", null)
        } //end reader.onload
      } //end event.target.files[0]

      this.props.updateScreenerFieldDirect("screener", "logoid", uuidv4())
      this.props.updateScreenerFieldDirect(
        "screener",
        "logoext",
        event.target.files[0].name.split(".").pop()
      )
    } else {
      this.props.updateScreenerForm(event.target.name, event.target.value)
    }
  }

  render() {
    const {
      templatecreate,
      templatekeyword,
      isLoadingTemplateCheck,
    } = this.props
    const { templateid } = this.state

    console.log("templatecreate")
    console.log(templatecreate)
    console.log("templatekeyword")
    console.log(templatekeyword)

    let fileError =
      templatecreate &&
      templatecreate.error &&
      (templatecreate.error.screenerlogo ||
        templatecreate.error.imagewidth ||
        templatecreate.error.imagesize ||
        templatecreate.error.imageextension)
        ? true
        : false

    let landingUploadTextError =
      templatecreate && templatecreate.error && templatecreate.error.uploadtext
        ? true
        : false
    let landingUploadSubTextError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.uploadsubtext
        ? true
        : false

    let s3MediaURL =
      templatecreate && templatecreate.screener && templatecreate.screener.id
        ? `https://${process.env.S3BUCKETMEDIA}.s3.amazonaws.com/template/${templatecreate.screener.id}/`
        : null

    return (
      <>
        <div class="form-group">
          <label for="logoUpload">Header image</label>
          <div class="d-flex flex-row align-items-center justify-content-start">
            <div class="custom-file">
              <input
                type="file"
                class={`custom-file-input ${fileError ? "is-invalid" : ""}`}
                id="customFile"
                name="logoUpload"
                id="logoUpload"
                aria-describedby="logoUploadHelp"
                onChange={this.handleUpdate}
              />
              <label class="custom-file-label" for="customFile">
                Choose file
              </label>
            </div>
            {/* <input
              type="file"
              class="form-control-file"
              name="logoUpload"
              id="logoUpload"
              aria-describedby="logoUploadHelp"
              onChange={this.handleUpdate}
            /> */}
          </div>
          {templatecreate.screener &&
          (templatecreate.screener.logo ||
            templatecreate.screener.logoexist) ? (
            <div
              class="round-corners p-3 my-2 d-flex flex-column align-items-center justify-content-center"
              style={{
                backgroundColor: templatecreate.landing.uploadbg || "#f2f2f2",
              }}
            >
              {templatecreate.screener.logo ? (
                <img class="preview-image" src={templatecreate.screener.logo} />
              ) : null}
              {s3MediaURL && templatecreate.screener.logoexist ? (
                <img
                  class="preview-image"
                  srcSet={`${this.insertDensity(
                    s3MediaURL + templatecreate.screener.logoexist,
                    "3x"
                  )} 3x, ${this.insertDensity(
                    s3MediaURL + templatecreate.screener.logoexist,
                    "2x"
                  )} 2x, ${this.insertDensity(
                    s3MediaURL + templatecreate.screener.logoexist,
                    "1x"
                  )} 1x`}
                  src={`${this.insertDensity(
                    s3MediaURL + templatecreate.screener.logoexist,
                    "1x"
                  )}`}
                />
              ) : null}
            </div>
          ) : null}

          <small id="logoUploadHelp" class="form-text text-muted">
            Upload the image you'd like to appear at the top of the screener
          </small>
          {fileError ? (
            <>
              <div class="invalid-feedback">
                {templatecreate.error.screenerlogo}
              </div>
              <div class="invalid-feedback">
                {templatecreate.error.imagewidth}
              </div>
              <div class="invalid-feedback">
                {templatecreate.error.imagesize}
              </div>
              <div class="invalid-feedback">
                {templatecreate.error.imageextension}
              </div>
            </>
          ) : null}
        </div>
        <div class="form-group">
          {/* <label for="">Header color</label> */}
          <div class="font-weight-bold">Header color</div>
          {/* <CompactPicker
            color={templatecreate.colorPicker || "#f2f2f2"}
            onChangeComplete={e => this.handleUpdateColor(e)}
          /> */}
          <TwitterPicker
            triangle="hide"
            colors={[
              "#FF6900",
              "#FCB900",
              "#7BDCB5",
              "#00D084",
              "#8ED1FC",
              "#0693E3",
              "#E6E6E6",
              "#EB144C",
              "#F78DA7",
              "#9900EF",
            ]}
            color={templatecreate.landing.uploadbg || "#F2F2F2"}
            onChangeComplete={(e) => this.handleUpdateColor(e)}
          />
          <small id="bgColorHelp" class="form-text text-muted">
            Select the color you'd like to appear as the background behind the
            header image
          </small>
        </div>
        <div class="form-group">
          <label for="screenerName">Landing page text</label>
          <div class="mb-3">
            <input
              type="text"
              class={`form-control mt-1 ${
                landingUploadTextError ? "is-invalid" : ""
              }`}
              name="landingPageTitle"
              id="landingPageTitle"
              aria-describedby="landingPageTitleHelp"
              placeholder="Enter landing page title"
              onChange={(e) => {
                this.updateLandingdataOption("uploadtext", e.target.value, "en")
              }}
              value={
                templatecreate.landing &&
                templatecreate.landing.landingdata &&
                templatecreate.landing.landingdata.find(
                  (item) => item.lang === "en"
                ) &&
                templatecreate.landing.landingdata.find(
                  (item) => item.lang === "en"
                ).uploadtext
                  ? templatecreate.landing.landingdata.find(
                      (item) => item.lang === "en"
                    ).uploadtext
                  : ""
              }
            />
            <small id="landingPageTitleHelp" class="form-text text-muted">
              This title will appear at the top of your screener landing page
            </small>
            {landingUploadTextError ? (
              <div class="invalid-feedback">
                {templatecreate.error.uploadtext}
              </div>
            ) : null}
          </div>
          <div class="mb-3">
            <input
              type="text"
              class={`form-control mt-1 ${
                landingUploadSubTextError ? "is-invalid" : ""
              }`}
              name="landingPageSubTitle"
              id="landingPageSubTitle"
              aria-describedby="landingPageTitleHelp"
              placeholder="Enter landing page text"
              onChange={(e) => {
                this.updateLandingdataOption(
                  "uploadsubtext",
                  e.target.value,
                  "en"
                )
              }}
              value={
                templatecreate.landing &&
                templatecreate.landing.landingdata &&
                templatecreate.landing.landingdata.find(
                  (item) => item.lang === "en"
                ) &&
                templatecreate.landing.landingdata.find(
                  (item) => item.lang === "en"
                ).uploadsubtext
                  ? templatecreate.landing.landingdata.find(
                      (item) => item.lang === "en"
                    ).uploadsubtext
                  : ""
              }
            />
            <small id="landingPageTitleHelp" class="form-text text-muted">
              This instruction text will appear below the landing page title
            </small>
            {landingUploadSubTextError ? (
              <div class="invalid-feedback">
                {templatecreate.error.uploadsubtext}
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
