export function copySendGroup(mode, deliver, sendgroup, users, templates) {
  console.log("actions - editSendGroup - copySendGroup - pre")
  return dispatch => {
    dispatch({
      type: "SENDGROUP_EDIT_COPY",
      data: { deliver, sendgroup, users, templates },
      mode: mode,
    })
  }
}
