//React
import React from "react"
import { connect } from "react-redux"

//Packages
import {
  AsYouType,
  parsePhoneNumber,
  parsePhoneNumberFromString,
  ParseError,
} from "libphonenumber-js/max"

//Actions
import {
  updateSendGroupOption,
  updateSendGroupDay,
} from "../../actions/createSendGroup"

//Components
import Loading from "../loading"

//Icons
import { FaPlus, FaTimes } from "react-icons/fa"

//Redux
const mapStateToProps = ({ sendgroupcreate }) => {
  return { sendgroupcreate }
}

const mapDispatchToProps = (dispatch) => ({
  updateSendGroupOption: (mode, formoption, marker, val, prop) =>
    dispatch(updateSendGroupOption(mode, formoption, marker, val, prop)),
  updateSendGroupDay: (marker, val, prop) =>
    dispatch(updateSendGroupDay(marker, val, prop)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}

  updateSendGroupDay = (marker, val, prop) => {
    this.props.updateSendGroupDay(marker, val, prop)
  }

  updateSendGroupOption = (mode, formoption, marker, val, prop) => {
    if (mode === "update" && prop === "mobile") {
      const asYouType = new AsYouType("US")

      let mobileObj = {
        format: null,
        provided: null,
        valid: null,
      }
      const phonePartial = asYouType.input(val)
      console.log(
        "appSendGroupUserForm - this.updateSendGroupOption - phonePartial "
      )
      console.log(phonePartial)
      const phoneNumber = asYouType.getNumber()

      // const phoneNumber = parsePhoneNumberFromString(val, "US")
      if (phoneNumber) {
        console.log(
          "appSendGroupUserForm - this.updateSendGroupOption - phoneNumber "
        )
        console.log(phoneNumber)
        mobileObj = {
          format: phoneNumber.format("E.164"),
          provided: phoneNumber.format("NATIONAL"),
          valid: phoneNumber.isValid(),
        }
      } else {
        mobileObj = {
          format: null,
          provided: phonePartial,
          valid: false,
        }
      }
      this.props.updateSendGroupOption(
        mode,
        formoption,
        marker,
        mobileObj,
        prop
      )
    } else {
      this.props.updateSendGroupOption(mode, formoption, marker, val, prop)
    }
  }

  render() {
    const { sendgroupcreate } = this.props

    console.log("sendgroupcreate")
    console.log(sendgroupcreate)

    let sendGroupUsersLengthError =
      sendgroupcreate &&
      sendgroupcreate.error &&
      sendgroupcreate.error.sendgroupuserslength
        ? true
        : false
    let sendGroupUsersError =
      sendgroupcreate &&
      sendgroupcreate.error &&
      sendgroupcreate.error.sendgroupuserserror
        ? true
        : false

    return (
      <>
        <table class="table table-striped table-sendgroup">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Mobile number</th>
              <th scope="col" class="header-day">
                Su
              </th>
              <th scope="col" class="header-day">
                M
              </th>
              <th scope="col" class="header-day">
                Tu
              </th>
              <th scope="col" class="header-day">
                W
              </th>
              <th scope="col" class="header-day">
                Th
              </th>
              <th scope="col" class="header-day">
                F
              </th>
              <th scope="col" class="header-day">
                Sa
              </th>
            </tr>
          </thead>
          <tbody>
            {sendgroupcreate && sendgroupcreate.users
              ? sendgroupcreate.users.map((user, i) => (
                  <tr key={`sendgroup-row-${i}`}>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm"
                        onClick={(event) => {
                          this.updateSendGroupOption("delete", "users", i)
                        }}
                      >
                        <FaTimes class="dkorange-1" size={11} />
                      </button>
                    </td>
                    <td>
                      <input
                        class="form-check-input-option"
                        // class={`form-check-input-option ${
                        //   sendgroupcreate.error.sendgroupusersitems &&
                        //   sendgroupcreate.error.sendgroupusersitems[i] &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices[j] &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices[j].title
                        //     ? "is-invalid"
                        //     : ""
                        // }`}
                        type="text"
                        name="gridRadiosOption"
                        placeholder="First name"
                        id={`form-sendgroup-input-fname-${i}`}
                        value={sendgroupcreate.users[i].fname}
                        // mode, formoption, marker, pos, val, prop
                        // mode, formoption, marker, pos, val, prop
                        onChange={(event) => {
                          this.updateSendGroupOption(
                            "update",
                            "users",
                            i,
                            event.target.value,
                            "fname"
                          )
                        }}
                      />

                      {sendgroupcreate &&
                      sendgroupcreate.subscription &&
                      sendgroupcreate.subscription.status &&
                      sendgroupcreate.users &&
                      sendgroupcreate.users[i] &&
                      sendgroupcreate.users[i].mobile &&
                      sendgroupcreate.users[i].mobile.format &&
                      sendgroupcreate.subscription.status.find(
                        (usersub) =>
                          usersub.id === sendgroupcreate.users[i].mobile.format
                      ) &&
                      (sendgroupcreate.subscription.status.find(
                        (usersub) =>
                          usersub.id === sendgroupcreate.users[i].mobile.format
                      ).optout === true ||
                        sendgroupcreate.subscription.status.find(
                          (usersub) =>
                            usersub.id ===
                            sendgroupcreate.users[i].mobile.format
                        ).message) ? (
                        <div class="red-1 line-height-0">
                          <small>
                            {sendgroupcreate.subscription.status.find(
                              (usersub) =>
                                usersub.id ===
                                sendgroupcreate.users[i].mobile.format
                            ).message
                              ? sendgroupcreate.subscription.status.find(
                                  (usersub) =>
                                    usersub.id ===
                                    sendgroupcreate.users[i].mobile.format
                                ).message
                              : sendgroupcreate.subscription.status.find(
                                  (usersub) =>
                                    usersub.id ===
                                    sendgroupcreate.users[i].mobile.format
                                ).optout === true
                              ? "This user unsubscribed from your messages"
                              : ""}
                          </small>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <input
                        class="form-check-input-option "
                        // class={`form-check-input-option ${
                        //   sendgroupcreate.error.sendgroupusersitems &&
                        //   sendgroupcreate.error.sendgroupusersitems[i] &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices[j] &&
                        //   sendgroupcreate.error.sendgroupusersitems[i].choices[j].title
                        //     ? "is-invalid"
                        //     : ""
                        // }`}
                        type="text"
                        name="gridRadiosOption"
                        placeholder="Last name"
                        id={`form-sendgroup-input-lname-${i}`}
                        value={sendgroupcreate.users[i].lname}
                        onChange={(event) => {
                          this.updateSendGroupOption(
                            "update",
                            "users",
                            i,
                            event.target.value,
                            "lname"
                          )
                        }}
                      />
                    </td>
                    <td>
                      <input
                        class={`form-check-input-option form-sendgroup-input-mobile  ${
                          sendgroupcreate.error.sendgroupusersitems &&
                          sendgroupcreate.error.sendgroupusersitems[i] &&
                          sendgroupcreate.error.sendgroupusersitems[i].mobile &&
                          (sendgroupcreate.error.sendgroupusersitems[i].mobile
                            .format ||
                            sendgroupcreate.error.sendgroupusersitems[i].mobile
                              .valid ||
                            sendgroupcreate.error.sendgroupusersitems[i].mobile
                              .duplicate)
                            ? "is-invalid no-bg"
                            : ""
                        }`}
                        type="text"
                        name="gridRadiosOption"
                        placeholder="Mobile number"
                        id={`form-sendgroup-input-mobile-${i}`}
                        value={sendgroupcreate.users[i].mobile.provided}
                        onChange={(event) =>
                          this.updateSendGroupOption(
                            "update",
                            "users",
                            i,
                            event.target.value,
                            "mobile"
                          )
                        }
                      />
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-su-${i}`}
                          checked={sendgroupcreate.users[i].send[1]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 1)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-mo-${i}`}
                          checked={sendgroupcreate.users[i].send[2]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 2)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-tu-${i}`}
                          checked={sendgroupcreate.users[i].send[3]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 3)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-we-${i}`}
                          checked={sendgroupcreate.users[i].send[4]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 4)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-th-${i}`}
                          checked={sendgroupcreate.users[i].send[5]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 5)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-fr-${i}`}
                          checked={sendgroupcreate.users[i].send[6]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 6)
                          }}
                        />
                      </div>
                    </td>
                    <td class="header-day-input">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`form-sendgroup-check-sa-${i}`}
                          checked={sendgroupcreate.users[i].send[7]}
                          onChange={(event) => {
                            this.updateSendGroupDay(i, event.target.checked, 7)
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        {sendGroupUsersLengthError ? (
          <div class="invalid-feedback p-2">
            {sendgroupcreate.error.sendgroupuserslength}
          </div>
        ) : null}
        {sendGroupUsersError ? (
          <div class="invalid-feedback p-2">
            {sendgroupcreate.error.sendgroupuserserror}
          </div>
        ) : null}
        <button
          type="button"
          class="btn btn-sm"
          // mode, formoption, marker, pos, val, prop
          onClick={(event) => this.updateSendGroupOption("add", "users")}
        >
          <FaPlus class="dkgreen-1" size={12} />
          <small class="dkgreen-1 pl-1">Add recipient</small>
        </button>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
