//React
import React from "react"
import { Router } from "@reach/router"

//Components
import AppHome from "../components/appHome/consoleHome"
import AppCampaignDetail from "../components/appCampaignDetail"
import SignUp from "../components/appSignup/signUp"
import PasswordReset from "../components/appLogin/passwordReset"
import PrivateRoute from "../components/PrivateRoute"

const App = () => (
  <Router>
    <PrivateRoute path="/app/numbers/" component={AppHome} />
    <PrivateRoute path="/app/screener/" component={AppHome} />
    <PrivateRoute path="/app/sendgroup/" component={AppHome} />
    <PrivateRoute path="/app/trends/" component={AppCampaignDetail} />
    <PrivateRoute path="/app/detail/" component={AppCampaignDetail} />
    <PrivateRoute path="/app/screener/create/" component={AppCampaignDetail} />
    <PrivateRoute path="/app/sendgroup/create/" component={AppCampaignDetail} />

    <SignUp path="/app/signup" />
    <PasswordReset path="/app/password" />
  </Router>
)

export default App
