//React
import React from "react"
import { connect } from "react-redux"

//Actions
import {
  checkKeyword,
  updateScreenerForm,
  removeScreenerItem,
  updateScreenerOption,
  updateScreenerField,
  updateDeliverOption,
} from "../../actions/createScreener"

//Icons
import { FaTimes, FaPlus } from "react-icons/fa"

//Redux
const mapStateToProps = ({ templatecreate, isLoadingTemplateCheck }) => {
  return { templatecreate, isLoadingTemplateCheck }
}

const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val, mode) =>
    dispatch(updateScreenerForm(prop, val, mode)),
  removeScreenerItem: (prop, pos) => dispatch(removeScreenerItem(prop, pos)),
  updateScreenerOption: (mode, formoption, marker, pos, val, lang, prop) =>
    dispatch(
      updateScreenerOption(mode, formoption, marker, pos, val, lang, prop)
    ),
  updateScreenerField: (group, marker, prop, val, lang) =>
    dispatch(updateScreenerField(group, marker, prop, val, lang)),
  updateDeliverOption: (prop, val, marker, lang) =>
    dispatch(updateDeliverOption(prop, val, marker, lang)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const { templatecreate } = this.props

    if (
      templatecreate &&
      templatecreate.criteria &&
      templatecreate.criteria[0] &&
      templatecreate.criteria[0].name &&
      templatecreate.criteria[0].name === "default"
    ) {
      // This is called when an existing criteria set exists and we dont need to add a default criteria
      console.log("**doing nothing - criteria**")

      //Find the maximum 'pos' value in the criteria set, and increment it
      const maxPos = Math.max.apply(
        Math,
        templatecreate.criteria.map((o) => o.pos)
      )
      this.props.updateScreenerForm("criteriacounter", maxPos + 1)
    } else {
      // This is called when not existing criteria set exists and we DO need to add a default criteria
      console.log("**newCriteria")
      this.handleUpdate(
        { target: { name: "newCriteria" } },
        "conditionDefault",
        1
      )
      //We take care of updating the criteriacounter below
    }
  }

  updateField = (event, group, marker, prop, lang) => {
    this.props.updateScreenerField(
      group,
      marker,
      prop,
      event.target.value,
      lang
    )
  }
  updateOption = (val, mode, formoption, marker, pos, lang, prop) => {
    this.props.updateScreenerOption(
      mode,
      formoption,
      marker,
      pos,
      val,
      lang,
      prop
    )
  }
  updateDeliverOption = (prop, val, marker, lang) => {
    this.props.updateDeliverOption(prop, val, marker, lang)
  }
  removeSurveyItem = (prop, pos) => {
    this.props.removeScreenerItem(prop, pos)
  }

  handleUpdate = (event, mode, pos) => {
    console.log("handleUpdate - event")
    console.log(event)
    if (event.target.name === "newCriteria") {
      let inputJSON = {}
      switch (mode) {
        case "conditionDefault":
        case "condition":
          inputJSON = {
            type: "condition",
            name: mode === "conditionDefault" ? "default" : `condition${pos}`,
            pos: pos,
            title: mode === "conditionDefault" ? "default" : ``,
            isRequired: true,
            deliver: [
              {
                lang: "en",
                title: mode === "conditionDefault" ? "Thank you" : ``,
                text:
                  mode === "conditionDefault"
                    ? "Based on your responses, please call us for information on how to proceed"
                    : ``,
                displayimage: mode === "conditionDefault" ? "warning" : "none",
              },
            ],
            // choices: [{ lang: "en", data: ["", ""] }],
          }
          if (mode !== "conditionDefault") {
            inputJSON.operator = "and"
            inputJSON.test = {
              value: "",
              data: [{ dataname: "", dataval: "" }],
            }
          }
          console.log("inputJSON")
          console.log(inputJSON)
          this.props.updateScreenerForm(
            event.target.name,
            inputJSON,
            "criteria"
          )
          this.props.updateScreenerForm("criteriacounter", pos + 1)
          break
        default:
        // code block
      }

      return
    } else {
      this.props.updateScreenerForm(event.target.name, event.target.value)
    }
  }

  buildOptionItem(templatecreate, i, j) {
    return (
      <div
        class="d-flex flex-row align-items-center justify-content-start"
        key={`radio-option-${i}-${j}`}
      >
        <div class="form-check d-flex flex-row">
          {/* <input
            class="form-check-input"
            type="radio"
            name="gridRadiosTitle"
            name="gridRadios"
            id={`radio-${i}-${j}`}
            // value={templatecreate.survey[i].choices[j]}
          /> */}
          <div class="form-group">
            <select
              class={`form-control ${
                templatecreate.error.criteriaitems &&
                templatecreate.error.criteriaitems[i] &&
                templatecreate.error.criteriaitems[i].test &&
                templatecreate.error.criteriaitems[i].test.data &&
                templatecreate.error.criteriaitems[i].test.data[j] &&
                templatecreate.error.criteriaitems[i].test.data[j].dataname
                  ? "is-invalid"
                  : ""
              }`}
              style={{ minWidth: 100 }}
              id={`select-criteria-${i}-${j}`}
              onChange={(event) => {
                console.log("event.target - changer")
                console.log(event.target.value)
                this.updateOption(
                  templatecreate.survey.find(
                    (surveyitem) =>
                      surveyitem.title.default.trim() ===
                      event.target.value.trim()
                  ).name,
                  "update",
                  "criteriaoption",
                  i,
                  j,
                  "en",
                  "dataname"
                )
              }}
            >
              <option>Select question...</option>

              {templatecreate && templatecreate.survey
                ? templatecreate.survey
                    .filter((selectitem) => selectitem.type === "radiogroup")
                    .map((selectitem, k) => (
                      <option
                        selected={
                          templatecreate.criteria[i].test.data[j].dataname ===
                          selectitem.name
                            ? true
                            : false
                        }
                      >
                        {selectitem.title.default}
                      </option>
                    ))
                : null}
            </select>
          </div>
          <div class="p-2 px-4">equals</div>
          <div class="form-group">
            <select
              class={`form-control ${
                templatecreate.error.criteriaitems &&
                templatecreate.error.criteriaitems[i] &&
                templatecreate.error.criteriaitems[i].test &&
                templatecreate.error.criteriaitems[i].test.data &&
                templatecreate.error.criteriaitems[i].test.data[j] &&
                templatecreate.error.criteriaitems[i].test.data[j].dataval
                  ? "is-invalid"
                  : ""
              }`}
              style={{ minWidth: 100 }}
              id={`select-criteria-${i}-${j}`}
              disabled={
                templatecreate &&
                templatecreate.survey &&
                templatecreate.survey.find(
                  (surveyitem) =>
                    surveyitem.name ===
                    templatecreate.criteria[i].test.data[j].dataname
                ) &&
                templatecreate.survey.find(
                  (surveyitem) =>
                    surveyitem.name ===
                    templatecreate.criteria[i].test.data[j].dataname
                ).choices &&
                templatecreate.survey.find(
                  (surveyitem) =>
                    surveyitem.name ===
                    templatecreate.criteria[i].test.data[j].dataname
                ).choices[0]
                  ? false
                  : true
              }
              onChange={(event) => {
                this.updateOption(
                  event.target.value,
                  "update",
                  "criteriaoption",
                  i,
                  j,
                  "en",
                  "dataval"
                )
              }}
            >
              <option>Select...</option>

              {templatecreate &&
              templatecreate.survey &&
              templatecreate.survey.find(
                (surveyitem) =>
                  surveyitem.name ===
                  templatecreate.criteria[i].test.data[j].dataname
              ) &&
              templatecreate.survey.find(
                (surveyitem) =>
                  surveyitem.name ===
                  templatecreate.criteria[i].test.data[j].dataname
              ).choices
                ? templatecreate.survey
                    .find(
                      (surveyitem) =>
                        surveyitem.name ===
                        templatecreate.criteria[i].test.data[j].dataname
                    )
                    .choices.map((selectitem, k) => (
                      <option
                        value={selectitem.value}
                        selected={
                          templatecreate.criteria[i].test.data[j].dataval ===
                          selectitem.value
                            ? true
                            : false
                        }
                      >
                        {selectitem.value}
                      </option>
                    ))
                : null}
            </select>
          </div>
          {/* <div class="form-group">
            <label
              class="form-check-label font-weight-light"
              for={`radio-${i}-${j}`}
            >
              <input
                class="form-check-input-option"
                type="text"
                name="gridRadiosOption"
                placeholder="Enter an option"
                id={`radio-option-label-${i}-${j}`}
                value={templatecreate.criteria[i].test.data[j].dataval}
                onChange={event =>
                  this.updateOption(
                    event.target.value,
                    "update",
                    "criteriaoption",
                    i,
                    j,
                    "en",
                    "dataval"
                  )
                }
              />
            </label>
          </div> */}
        </div>
        <button
          type="button"
          class="btn btn-sm"
          onClick={(event) =>
            this.updateOption(
              event.target.value,
              "delete",
              "criteriaoption",
              i,
              j,
              "en"
            )
          }
        >
          <FaTimes class="dkorange-1" size={11} />

          <small class="dkorange-1 pl-1"></small>
        </button>
      </div>
    )
  }
  render() {
    const { templatecreate } = this.props
    const { templateid } = this.state

    console.log("templatecreate")
    console.log(templatecreate)

    let criteriaContainsError =
      templatecreate && templatecreate.error && templatecreate.error.surveyerror
        ? true
        : false

    const renderSurvey = templatecreate.criteria
      ? templatecreate.criteria.map((item, i) => {
          if (item.type === "condition") {
            let defaultCondition =
              templatecreate.criteria &&
              templatecreate.criteria[i] &&
              templatecreate.criteria[i].name === "default"
                ? true
                : false

            return (
              <div
                class="form-question-group d-flex flex-row justify-content-start align-items-start"
                key={`radio-item-${i}`}
              >
                {defaultCondition ? (
                  <button
                    type="button"
                    class="btn btn-sm btn-placeholder"
                  ></button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-sm"
                    onClick={() => this.removeSurveyItem("criteria", i)}
                  >
                    <FaTimes
                      class="red-1"
                      size={16}
                      style={{ marginTop: -6 }}
                    />
                  </button>
                )}

                <div
                  class={`w-100 ${
                    defaultCondition
                      ? "form-item-container-default-condition"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    class={`form-item-title form-item-condition-title ${
                      templatecreate.error.criteriaitems &&
                      templatecreate.error.criteriaitems[i] &&
                      templatecreate.error.criteriaitems[i].title
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter a name for this condition"
                    id={`radio-title-${i}`}
                    onChange={(e) =>
                      this.updateField(e, "criteria", i, "title")
                    }
                    value={templatecreate.criteria[i].title}
                  />

                  {defaultCondition ? null : (
                    <>
                      <div class="form-group">
                        <label for={`select-operator-${i}`}>
                          Tag a user's response with this condition if:
                        </label>

                        <select
                          class="form-control"
                          id={`select-operator-${i}`}
                          onChange={(e) =>
                            this.updateField(e, "criteria", i, "operator")
                          }
                          value={
                            templatecreate.criteria &&
                            templatecreate.criteria[i] &&
                            templatecreate.criteria[i].operator
                              ? templatecreate.criteria[i].operator
                              : null
                          }
                        >
                          <option value="and">ALL of the below are true</option>
                          <option value="or">ANY of the below is true</option>
                        </select>
                      </div>

                      {item && item.test && item.test.data
                        ? item.test.data.map((choice, j) =>
                            this.buildOptionItem(templatecreate, i, j)
                          )
                        : null}
                      <button
                        type="button"
                        class="btn btn-sm"
                        onClick={(event) =>
                          this.updateOption(
                            event.target.value,
                            "add",
                            "criteriaoption",
                            i,
                            item.test.data.length,
                            "en"
                          )
                        }
                      >
                        <FaPlus class="dkgreen-1" size={12} />
                        <small class="dkgreen-1 pl-1">Add option</small>
                      </button>
                    </>
                  )}
                  <div class="form-group px-1">
                    {defaultCondition ? (
                      <div class="mb-3">
                        <div class="form-item-default-condition-title bg-sky-1 white-1 round-corners-med px-1">
                          <strong>Default condition</strong>
                        </div>
                        <small class="gray-2">
                          Criteria are evaluated from top to bottom as listed on
                          this page, with this as the default response if no
                          other criteria are matched
                        </small>
                      </div>
                    ) : null}
                    <label for={`select-operator-${i}`}>
                      Respond with this
                    </label>
                    <input
                      type="text"
                      class={`form-item-title form-item-deliver form-item-deliver-title ${
                        templatecreate.error.criteriaitems &&
                        templatecreate.error.criteriaitems[i] &&
                        templatecreate.error.criteriaitems[i].deliver &&
                        templatecreate.error.criteriaitems[i].deliver[0] &&
                        templatecreate.error.criteriaitems[i].deliver[0].title
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Response title"
                      id={`response-title-${i}`}
                      onChange={(e) =>
                        this.updateDeliverOption(
                          "title",
                          e.target.value,
                          i,
                          "en"
                        )
                      }
                      value={
                        templatecreate.criteria &&
                        templatecreate.criteria[i] &&
                        templatecreate.criteria[i].deliver &&
                        templatecreate.criteria[i].deliver.find(
                          (item) => item.lang === "en"
                        ) &&
                        templatecreate.criteria[i].deliver.find(
                          (item) => item.lang === "en"
                        ).title
                          ? templatecreate.criteria[i].deliver.find(
                              (item) => item.lang === "en"
                            ).title
                          : null
                      }
                    />
                    <input
                      type="text"
                      class={`form-item-title form-item-deliver form-item-deliver-text ${
                        templatecreate.error.criteriaitems &&
                        templatecreate.error.criteriaitems[i] &&
                        templatecreate.error.criteriaitems[i].deliver &&
                        templatecreate.error.criteriaitems[i].deliver[0] &&
                        templatecreate.error.criteriaitems[i].deliver[0].text
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Response text"
                      id={`response-text-${i}`}
                      onChange={(e) =>
                        this.updateDeliverOption(
                          "text",
                          e.target.value,
                          i,
                          "en"
                        )
                      }
                      value={
                        templatecreate.criteria &&
                        templatecreate.criteria[i] &&
                        templatecreate.criteria[i].deliver &&
                        templatecreate.criteria[i].deliver.find(
                          (item) => item.lang === "en"
                        ) &&
                        templatecreate.criteria[i].deliver.find(
                          (item) => item.lang === "en"
                        ).text
                          ? templatecreate.criteria[i].deliver.find(
                              (item) => item.lang === "en"
                            ).text
                          : null
                      }
                    />
                    <label for={`select-criteriaimage-${i}`}>
                      Display image
                    </label>
                    <select
                      class={`form-control ${
                        templatecreate.error.criteriaitems &&
                        templatecreate.error.criteriaitems[i] &&
                        templatecreate.error.criteriaitems[i].deliver &&
                        templatecreate.error.criteriaitems[i].deliver[0] &&
                        templatecreate.error.criteriaitems[i].deliver[0]
                          .displayimage
                          ? "is-invalid"
                          : ""
                      }`}
                      style={{ minWidth: 100 }}
                      id={`select-criteriaimage-${i}`}
                      onChange={(e) =>
                        this.updateDeliverOption(
                          "displayimage",
                          e.target.value,
                          i,
                          "en"
                        )
                      }
                    >
                      <option
                        selected={
                          templatecreate.criteria &&
                          templatecreate.criteria[i] &&
                          templatecreate.criteria[i].deliver &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ) &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage === "none"
                            ? true
                            : false
                        }
                        value="none"
                      >
                        None
                      </option>
                      <option
                        selected={
                          templatecreate.criteria &&
                          templatecreate.criteria[i] &&
                          templatecreate.criteria[i].deliver &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ) &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage === "success"
                            ? true
                            : false
                        }
                        value="success"
                      >
                        Success (green checkmark)
                      </option>
                      <option
                        selected={
                          templatecreate.criteria &&
                          templatecreate.criteria[i] &&
                          templatecreate.criteria[i].deliver &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ) &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage === "warning"
                            ? true
                            : false
                        }
                        value="warning"
                      >
                        Warning (orange triangle)
                      </option>
                      <option
                        selected={
                          templatecreate.criteria &&
                          templatecreate.criteria[i] &&
                          templatecreate.criteria[i].deliver &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ) &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage &&
                          templatecreate.criteria[i].deliver.find(
                            (item) => item.lang === "en"
                          ).displayimage === "danger"
                            ? true
                            : false
                        }
                        value="danger"
                      >
                        Danger (red X)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            )
          }
        })
      : null
    console.log("[[[templatecreate]]]")
    console.log(templatecreate)
    return (
      <>
        <div class="form-group">
          <label for="newQuestion">Evaluation criteria</label>
          <div class="dropdown">
            <button
              class="btn btn-dkgreen btn-cta"
              type="button"
              name="newCriteria"
              id="newCriteria"
              onClick={(e) => {
                this.handleUpdate(
                  e,
                  "condition",
                  templatecreate.criteriacounter
                )
              }}
            >
              Add condition +
              {/* <FaPlus class="dkgreen-1 btn-arrow" size={14} /> */}
            </button>
          </div>
        </div>
        {criteriaContainsError ? (
          <div class="invalid-feedback">
            {templatecreate.error.criteriaerror}
          </div>
        ) : null}
        <div class="form-group">{renderSurvey}</div>
      </>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
