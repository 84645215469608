//React
import React from "react"
import { connect } from "react-redux"

//Packages
import moment from "moment"
import {
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
  Label,
} from "recharts"

//Actions
import {
  getVideoCollection,
  getCampaignFilters,
  updateSelectedOptions,
} from "../../actions/getVideoCollection"

//Components
import Loading from "../loading"

//Redux
const mapStateToProps = ({
  videosgrouped,
  filterdata,
  reportStartDate,
  reportEndDate,
  isLoading,
  isLoadingVideoMini,
}) => {
  return {
    videosgrouped,
    filterdata,
    reportStartDate,
    reportEndDate,
    isLoading,
    isLoadingVideoMini,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedOptions: (templateid, group, name, val) =>
    dispatch(updateSelectedOptions(templateid, group, name, val)),
  getVideoCollection: (
    templateid,
    startdate,
    enddate,
    campaignfilter,
    userfilter
  ) =>
    dispatch(
      getVideoCollection(
        templateid,
        startdate,
        enddate,
        campaignfilter,
        userfilter
      )
    ),
  getCampaignFilters: (mode, templateidarray, templateid) =>
    dispatch(getCampaignFilters(mode, templateidarray, templateid)),
})

const CustomSymbol = ({ size, color, borderWidth, borderColor, cx, cy }) => (
  <g>
    <circle
      cx={cx}
      cy={cy}
      fill="#fff"
      r={4}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      cx={cx}
      cy={cy}
      r={8}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
)

class CustomDataPointLabel extends React.Component {
  render() {
    const { x, y, width, height, value, color } = this.props

    if (value > 0) {
      return (
        <g style={{ zIndex: 10, borderWidth: 1 }}>
          <text
            x={x + width + 5}
            y={y + 7}
            // x={x + width / 2 + 15}
            // y={y - radius + 15}
            fill={color ? color : "#333"}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="0.7em"
            fontWeight="600"
          >
            {value}
          </text>
        </g>
      )
    } else {
      return null
    }
  }
}

// const CustomDataPointLabel = props => {
//   const { x, y, width, height, value, color } = props
//   const radius = 10
//   if (value > 0) {
//     return (
//       <g style={{ zIndex: 10, borderWidth: 1 }}>
//         <text
//           x={x + width + 5}
//           y={y + 7}
//           // x={x + width / 2 + 15}
//           // y={y - radius + 15}
//           fill={color ? color : "#333"}
//           textAnchor="middle"
//           dominantBaseline="middle"
//           fontSize="0.7em"
//           fontWeight="600"
//         >
//           {value}
//         </text>
//       </g>
//     )
//   } else {
//     return
//   }
// }

const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const CustomTooltip = ({
  active,
  payload,
  label,
  aggregateOption,
  shareOption,
  barOptionReverse,
  templateid,
}) => {
  // console.log("tooltip - label")
  // console.log(label)
  // console.log("tooltip - active")
  // console.log(active)
  // console.log("tooltip - payload")
  // console.log(payload)
  // console.log("tooltip - templateid")
  // console.log(templateid)
  // console.log("tooltip - shareOption")
  // console.log(shareOption)
  if (active) {
    return payload && payload[0] ? (
      <div className="chart-tooltip">
        <div className="chart-tooltip-header">{`${moment(
          parseInt(payload[0].payload.time)
        ).format("MMM D")}`}</div>
        <div className="chart-tooltip-data">
          {barOptionReverse.map((item, i) => {
            // console.log("payload[0].payload.algos[item.name]")
            // console.log(payload[0].payload.algos[item.name])
            return payload[0].payload.algos[item.name] &&
              shareOption[item.name] ? (
              <div key={`${shareOption[item.name]}-${i}`}>
                {payload &&
                payload[0] &&
                payload[0].payload &&
                payload[0].payload.algos &&
                payload[0].payload.algos[item.name] &&
                payload[0].payload.algos[item.name].total ? (
                  <>
                    {payload[0].payload.algos[item.name].total}{" "}
                    <span
                      class="font-weight-light"
                      style={{ color: item.color }}
                    >
                      {capitalize(item.name)}
                    </span>
                  </>
                ) : null}

                {/* {icon} */}
              </div>
            ) : null
          })}
        </div>
      </div>
    ) : null
  }

  return null
}

const CustomXAxisTick = ({ x, y, stroke, payload }) => {
  // console.log("CustomXAxisTick - payload")
  // console.log(payload)
  if (payload && payload.coordinate) {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={6}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
          fontSize="0.8em"
        >
          {moment(payload.value).format("MMM D")}
        </text>
      </g>
    )
  } else {
    return null
  }
}
const CustomYAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        fontSize="0.8em"
        fontWeight="600"
      >
        {payload.value}
      </text>
    </g>
  )
}
const CustomYAxisLabel = (props) => {
  const { x, y, width, height, value } = props
  const radius = 10

  // console.log("x")
  // console.log(x)
  // console.log("y")
  // console.log(y)
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#666"
        textAnchor="start"
        dominantBaseline="end"
        fontSize="0.7em"
        transform="rotate(-90)"
      >
        {value}
      </text>
    </g>
  )
}
// class FooBar extends React.Component {
//   render() {
//     const { key, color } = this.props
//     console.log("*****bar - key")
//     console.log(key)
//     // return (
//     //   <Bar
//     //     dataKey={key}
//     //     stackId="a"
//     //     fill={color}
//     //     maxBarSize={20}
//     //     isAnimationActive={false}
//     //   >
//     //     <LabelList
//     //       dataKey={key}
//     //       content={<CustomDataPointLabel color={color} />}
//     //     />
//     //   </Bar>
//     // )
//   }
// }
// const BarComponent = props => {
//   const { key, color } = props
//   console.log("*****bar - key")
//   console.log(key)

//   return (
//     <Bar
//       dataKey={key}
//       stackId="a"
//       fill={color}
//       maxBarSize={20}
//       isAnimationActive={false}
//     >
//       <LabelList
//         dataKey={key}
//         content={<CustomDataPointLabel color={color} />}
//       />
//     </Bar>
//   )
// }

const TimeChart = ({
  barOption,
  barOptionReverse,
  data,
  ticks,
  axisLabels,
  aggregateOption,
  shareOption,
  templateid,
}) => (
  <>
    <ResponsiveContainer width="95%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 40, bottom: 40, left: 10, right: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          domain={["auto", "auto"]}
          name="Time"
          ticks={ticks}
          // type="number"
          interval={0}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          dataKey="algos['total']"
          name="Value"
          label={
            <CustomYAxisLabel
              value={axisLabels && axisLabels.y ? axisLabels.y : ""}
              x={-90}
              y={40}
            />
          }
          tick={<CustomYAxisTick />}
          allowDecimals={false}
        />

        <Tooltip
          content={
            <CustomTooltip
              aggregateOption={aggregateOption}
              shareOption={shareOption}
              barOptionReverse={barOptionReverse}
              templateid={templateid}
            />
          }
          cursor={{ fill: "rgba(0,0,0,0.04)" }}
        />
        {/* <Legend /> */}
        {/* === TOTALS === */}
        {/* {barOption.map((item, i) =>
        shareOption[item.name] === true &&
        aggregateOption.email === true &&
        aggregateOption.landing === true ? (
          <Bar
            dataKey={item.name}
            key={i}
            stackId="a"
            fill={item.color}
            maxBarSize={20}
            isAnimationActive={false}
            barSize={5}
          >
            <LabelList
              dataKey={item.name}
              content={<CustomDataPointLabel color={item.color} />}
            />
          </Bar>
        ) : null
      )} */}
        {/* === ALGOS ONLY === */}
        {barOption.map((item, i) =>
          shareOption[item.name] === true ? (
            <Bar
              dataKey={`algos[${item.name}].total`}
              key={i}
              stackId="a"
              fill={item.color}
              maxBarSize={20}
              isAnimationActive={false}
              barSize={5}
            >
              {/* <LabelList
                dataKey={`algos[${item.name}].total`}
                content={<CustomDataPointLabel color={item.color} />}
              /> */}
            </Bar>
          ) : null
        )}
        {/* === LANDING ONLY === */}
        {/* {barOption.map((item, i) =>
        shareOption[item.name] === true &&
        aggregateOption.email === false &&
        aggregateOption.landing === true ? (
          <Bar
            dataKey={`landing[${item.name}]`}
            key={i}
            stackId="a"
            fill={item.color}
            maxBarSize={20}
            isAnimationActive={false}
            barSize={5}
          >
            <LabelList
              dataKey={`landing[${item.name}]`}
              content={<CustomDataPointLabel color={item.color} />}
            />
          </Bar>
        ) : null
      )} */}
        {/* <Bar dataKey="algos[cond1]" fill="#8884d8" />
      <Bar dataKey="algos[default]" fill="#82ca9d" /> */}
      </BarChart>
    </ResponsiveContainer>
  </>
)

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      share: {
        cond1: true,
        default: true,
        // twitter: true,
        // instagram: true,
        // sms: true,
        // web: true,
        // tiktok: true,
        // snapchat: true,
        // download: true,
      },
      aggregate: {
        email: true,
        landing: true,
      },
    }
  }

  componentDidMount() {
    this.getTemplateMetadata()
  }

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    const { templateid, filterdata, videosgrouped } = this.props

    console.log("filterdata")
    console.log(filterdata)
    console.log("prevProps")
    console.log(prevProps)
    // if (
    //   filterdata &&
    //   filterdata[templateid] &&
    //   filterdata[templateid].data &&
    //   prevProps.filterdata.data !== filterdata[templateid].data
    // ) {
    //   console.log("**calling componentDidUpdate")
    //   this.getTemplateMetadata()
    // }
  }

  getTemplateMetadata() {
    const { templateid, videosgrouped } = this.props

    let campaignsAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaigns
        ? videosgrouped[templateid].campaigns
        : []

    if (campaignsAvailToFilter && campaignsAvailToFilter.length > 0) {
      campaignsAvailToFilter = campaignsAvailToFilter.map(
        (item) => item.campaignid
      )
    }
    this.props.getCampaignFilters(
      this.props.reportmode,
      campaignsAvailToFilter,
      this.props.templateid
    )
  }
  //From https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
  changeOption(group, selectorname, val) {
    // const { updateSelectedOptions } = this.props
    const { reportStartDate, reportEndDate } = this.props

    // console.log("chartBarStacked - changeOption - updateSelectedOptions")
    // console.log(updateSelectedOptions)
    this.props.updateSelectedOptions(
      this.props.templateid,
      group,
      selectorname,
      val
    )
    if (group === "campaignfilter") {
      let campaignFilterAllowed = [val]
      this.props.getVideoCollection(
        this.props.templateid,
        reportStartDate.unix(),
        reportEndDate.unix(),
        val === "all" ? "all" : campaignFilterAllowed
      )
    }
    if (group === "userfilter") {
      let userFilterAllowed = val
      console.log("userFilterAllowed")
      console.log(userFilterAllowed)
      this.props.getVideoCollection(
        this.props.templateid,
        reportStartDate.unix(),
        reportEndDate.unix(),
        "all",
        userFilterAllowed
      )
    }
    // this.setState(prevState => ({
    //   [option]: {
    //     ...prevState[option],
    //     [suboption]: value,
    //   },
    // }))

    // this.setState(prevState => ({
    //   [option]: {
    //     ...prevState[option],
    //     [suboption]: {
    //       ...prevState[option][suboption],
    //       [suboption]: value,
    //     },
    //   },
    // }))
  }

  truncate(str, max) {
    return str.length > max ? str.substring(0, max - 3) + "..." : str
  }

  lookUpCampaignFilterMetadata(campaignid, mode, truncate) {
    const { templateid, filterdata } = this.props

    let metadata =
      filterdata && filterdata[templateid] && filterdata[templateid].data
        ? filterdata[templateid].data.find(({ id }) => id === campaignid)
        : {}

    if (mode === "push") {
      if (truncate) {
        return metadata && metadata.title
          ? this.truncate(metadata.title, 30)
          : this.truncate(`Send group: ${campaignid}`, 30)
      } else {
        return metadata && metadata.title
          ? metadata.title
          : `Send group: ${campaignid}`
      }
    }

    if (mode === "keyword")
      if (truncate) {
        return metadata && metadata.keyword
          ? this.truncate(metadata.keyword, 30)
          : this.truncate(`Keyword: ${campaignid}`, 30)
      } else {
        return metadata && metadata.keyword
          ? metadata.keyword
          : `Keyword: ${campaignid}`
      }
  }
  campaignToolbar(barOption) {
    const { templateid, videosgrouped, isLoadingVideoMini } = this.props

    console.log("campaignToolbar - videosgrouped")
    console.log(videosgrouped)

    const campaignsAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaigns
        ? videosgrouped[templateid].campaigns
        : []

    console.log("campaignsAvailToFilter")
    console.log(campaignsAvailToFilter)

    const usersAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].users
        ? videosgrouped[templateid].users.sort((a, b) =>
            a.fname > b.fname ? -1 : 1
          )
        : []

    console.log("usersAvailToFilter")
    console.log(usersAvailToFilter)

    let filterLabel
    if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].mode === "push"
    ) {
      filterLabel = "Send group"
    } else if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].mode === "keyword"
    ) {
      filterLabel = "Keyword"
    }

    let isSendgroupTab = false
    if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaignfilter &&
      videosgrouped[templateid].campaignfilter.campaignid
    ) {
      isSendgroupTab = true
    } else {
      if (
        videosgrouped[templateid].userfilter &&
        videosgrouped[templateid].userfilter.userfilterid &&
        videosgrouped[templateid].userfilter.userfilterid !== "all"
      ) {
        isSendgroupTab = false
      } else {
        isSendgroupTab = true
      }
    }

    const isUserTab = !isSendgroupTab
    return (
      <div class="row d-flex flex-row mb-4">
        <div class="col-md-6">
          <ul class="nav nav-tabs" id="tabSelector" role="tablist">
            <li class="nav-item">
              <a
                class={`nav-link chart-toolbar-social-title ${
                  isSendgroupTab ? "active" : ""
                }`}
                id="sendgroup-tab"
                data-toggle="tab"
                href="#sendgroup"
                role="tab"
                aria-controls="sendgroup"
                aria-selected="true"
              >
                {filterLabel}
              </a>
            </li>
            <li class="nav-item">
              <a
                class={`nav-link chart-toolbar-social-title ${
                  isUserTab ? "active" : ""
                }`}
                id="user-tab"
                data-toggle="tab"
                href="#user"
                role="tab"
                aria-controls="user"
                aria-selected="false"
              >
                User
              </a>
            </li>
          </ul>
          <div
            class="tab-content border-bottom-1-gray-6 border-left-1-gray-6 border-right-1-gray-6"
            id="filter-container"
          >
            <div
              class={`tab-pane py-2 px-2 fade ${
                isSendgroupTab ? "show active" : ""
              }`}
              id="sendgroup"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div>
                {/* <div className="chart-toolbar-social-title">{filterLabel}</div> */}

                <select
                  class="form-control form-control-sm"
                  onChange={(e) => {
                    this.changeOption(
                      "campaignfilter",
                      "campaignid",
                      e.target.value
                    )
                  }}
                  value={
                    videosgrouped &&
                    templateid &&
                    videosgrouped[templateid] &&
                    videosgrouped[templateid].campaignfilter &&
                    videosgrouped[templateid].campaignfilter.campaignid
                      ? videosgrouped[templateid].campaignfilter.campaignid
                      : "all"
                  }
                >
                  <option value="all">All</option>
                  {campaignsAvailToFilter
                    ? campaignsAvailToFilter.map((campaign) => (
                        <option value={campaign.campaignid}>
                          {this.lookUpCampaignFilterMetadata(
                            campaign.campaignid,
                            this.props.reportmode,
                            true
                          )}
                        </option>
                      ))
                    : null}
                </select>
                <div class="pt-3" style={{ width: 60 }}>
                  {isLoadingVideoMini ? <Loading class="p-2 small" /> : null}
                </div>
              </div>
            </div>
            <div
              class={`tab-pane py-2 px-2 fade ${
                isUserTab ? "show active" : ""
              }`}
              id="user"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div>
                {/* <div className="chart-toolbar-social-title">User</div> */}

                <select
                  class="form-control form-control-sm"
                  onChange={(e) => {
                    this.changeOption(
                      "userfilter",
                      "userfilterid",
                      e.target.value
                    )
                  }}
                  value={
                    videosgrouped &&
                    templateid &&
                    videosgrouped[templateid] &&
                    videosgrouped[templateid].userfilter &&
                    videosgrouped[templateid].userfilter.userfilterid
                      ? videosgrouped[templateid].userfilter.userfilterid
                      : "all"
                  }
                >
                  <option value="all">All</option>
                  {usersAvailToFilter
                    ? usersAvailToFilter.map((user) => (
                        <option value={user.phone}>
                          {user.fname && user.lname
                            ? `${user.fname} ${user.lname}`
                            : user.phone}
                        </option>
                      ))
                    : null}
                </select>
                <div class="pt-3" style={{ width: 60 }}>
                  {isLoadingVideoMini ? <Loading class="p-2 small" /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-tabs" id="tabSelectorCondition" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link chart-toolbar-social-title active"
                id="condition-tab"
                data-toggle="tab"
                href="#condition"
                role="tab"
                aria-controls="condition"
                aria-selected="true"
              >
                Condition
              </a>
            </li>
          </ul>
          <div
            class="tab-content border-bottom-1-gray-6 border-left-1-gray-6 border-right-1-gray-6"
            id="filter-container-condition"
          >
            <div
              class="tab-pane py-2 px-2 fade show active"
              id="condition"
              role="tabpanel"
              aria-labelledby="condition-tab"
            >
              {this.socialIconToolbar(barOption)}
            </div>
          </div>
        </div>
      </div>
    )
  }
  socialIconToolbar(barOption) {
    const selectedConditions =
      this.props.videosgrouped &&
      this.props.templateid &&
      this.props.videosgrouped[this.props.templateid]
        ? this.props.videosgrouped[this.props.templateid].selected
        : []

    console.log("chartBarStacked - socialIconToolbar - videosgrouped")
    console.log(selectedConditions)

    return (
      <div className="chart-toolbar-social d-flex flex-column justify-content-center align-items-start">
        {/* <div>
          <div className="chart-toolbar-social-title">Click source</div>
          <div className="chart-toolbar-aggregate d-flex flex-row justify-content-center align-items-center">
            <button
              type="button"
              onClick={() => {
                this.changeOption(
                  "aggregate",
                  "email",
                  !this.state.aggregate.email
                )
              }}
              class={`btn btn-toolbar-social ${
                this.state.aggregate.email ? "active" : ""
              } d-flex flex-row justify-content-center align-items-center`}
            >
              <MdMailOutline
                className="chart-social-icon-tooltip grayblue-1"
                size={22}
              />
            </button>
            <button
              type="button"
              onClick={() => {
                this.changeOption(
                  "aggregate",
                  "landing",
                  !this.state.aggregate.landing
                )
              }}
              class={`btn btn-toolbar-social ${
                this.state.aggregate.landing ? "active" : ""
              } d-flex flex-row justify-content-center align-items-center`}
            >
              <MdOndemandVideo
                className="chart-social-icon-tooltip blue-1"
                size={22}
              />{" "}
            </button>
          </div>
        </div> */}
        <div>
          {/* <div className="chart-toolbar-social-title">Condition</div> */}
          <div className="chart-toolbar-share d-flex flex-row flex-wrap justify-content-start align-items-center">
            {barOption.map((item, i) => {
              let icon
              return (
                <button
                  type="button"
                  onClick={() => {
                    this.changeOption(
                      "selected",
                      item.name,
                      !selectedConditions[item.name]
                    )
                  }}
                  style={{
                    borderColor: selectedConditions[item.name]
                      ? item.color
                      : "#ccc",
                  }}
                  class={`btn btn-toolbar-social ${
                    selectedConditions[item.name] ? "active" : ""
                  } d-flex flex-row justify-content-center align-items-center`}
                >
                  <span
                    class={`font-weight-light ${
                      !selectedConditions[item.name] ? "darken" : ""
                    }`}
                    style={{ color: item.color }}
                  >
                    {capitalize(item.name)}
                  </span>
                  {selectedConditions[item.name] ? (
                    <span class="pl-1" style={{ color: item.color }}>
                      &#10004;
                    </span>
                  ) : null}

                  {/* <span>&#10004;</span> */}
                  {/* {icon} */}
                  {/* {this.state.share[item.name] ? (
                <FontAwesome.FaCheck
                  size={12}
                  color="rgba(0,0,0,0.6)"
                  style={{ marginTop: 10, marginLeft: -2 }}
                />
              ) : null} */}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    console.log("barchart - this.props.data")
    console.log(this.props.data)
    console.log("barchart - this state")
    console.log(this.state)

    let algoNames = {}
    if (this.props.data && this.props.data[0] && this.props.data[0].algos) {
      algoNames = Object.assign({}, this.props.data[0].algos)
      delete algoNames.total
    }

    const chartColors = [
      "#b00be5",
      "#f22e62",
      "#1875d3",
      "#079dd9",
      "#07b2d9",
      "#025159",
      "#025159",
      "#03a696",
      "#03a696",
      "#66cc00",
      "#90d948",
      "#f2ce16",
      "#f27405",
      "#f24405",
      "#f20505",
    ]

    const barOption = algoNames
      ? Object.keys(algoNames).map((item, i, sourceArray) => ({
          name: item,
          color: chartColors[((i + 1) / 10 - Math.floor((i + 1) / 10)) * 10],
          icon: "FaFacebookSquare",
        }))
      : []

    console.log("chartBarStacked - barOption")
    console.log(barOption)
    // const barOption = [
    //   {
    //     name: "facebook",
    //     color: "#1875d3",
    //     icon: "FaFacebookSquare",
    //   },
    //   {
    //     name: "twitter",
    //     color: "#07b2d9",
    //     icon: "FaTwitter",
    //   },
    //   {
    //     name: "instagram",
    //     color: "#f27405",
    //     icon: "FaInstagram",
    //   },
    //   {
    //     name: "snapchat",
    //     color: "#f2ce16",
    //     icon: "FaSnapchatGhost",
    //   },
    //   {
    //     name: "tiktok",
    //     color: "#b00be5",
    //     icon: "CustomTiktok",
    //   },
    //   {
    //     name: "sms",
    //     color: "#03a696",
    //     icon: "FaCommentDots",
    //   },
    //   {
    //     name: "web",
    //     color: "#025159",
    //     icon: "FaDesktop",
    //   },

    //   {
    //     name: "download",
    //     color: "#f22e62",
    //     icon: "MdFileDownload",
    //   },
    // ]

    const barOptionReverse = [...barOption].reverse()

    const selectedConditions2 =
      this.props.videosgrouped &&
      this.props.templateid &&
      this.props.videosgrouped[this.props.templateid]
        ? this.props.videosgrouped[this.props.templateid].selected
        : []

    return (
      <div class="w-100">
        <div class="chart-options">
          {this.campaignToolbar(barOption)}
          {/* {this.socialIconToolbar(barOption)} */}
          {/* <button
            type="button"
            onClick={() => {
              this.changeOption("social")
            }}
            class={`btn ${
              this.state.shareOption === "social" ? "btn-primary" : "btn-cta"
            }`}
          >
            Social totals
          </button>
          <button
            type="button"
            onClick={() => {
              this.changeOption("channel")
            }}
            class={`btn ${
              this.state.shareOption === "channel" ? "btn-primary" : "btn-cta"
            }`}
          >
            By channel
          </button> */}
        </div>
        <TimeChart
          barOption={barOption}
          barOptionReverse={barOptionReverse}
          aggregateOption={this.state.aggregate}
          shareOption={selectedConditions2}
          data={this.props.data}
          ticks={this.props.ticks}
          axisLabels={this.props.axisLabels}
          templateid={this.props.templateid}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
