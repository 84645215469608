//React
import React from "react"
import { connect } from "react-redux"
import { navigate } from "@reach/router"

//Amplify
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { amplifyconfig } from "../utils/config"

//Actions
import { logoutUser } from "../actions/userManagement"

//Redux
const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
})

//Configure
Amplify.configure(amplifyconfig)

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    let cid = Cache.getItem("cid")
    console.log("privateRoute - Cache - cid")
    console.log(cid)
    if (!cid) {
      console.log("privateRoute - noCid - signout")
      this.props.logoutUser()
    }
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        console.log("privateRoute - currentAuthenticatedUser - user")
        console.log(user)
      })
      .catch(error => {
        console.log("privateRoute - currentAuthenticatedUser - user - catch")
        console.log(error)
        this.props.logoutUser()
      })
  }
  render() {
    const { component: Component, location, ...rest } = this.props

    return (
      <div>
        <Component {...rest} />
      </div>
    )
  }
}

// export default PrivateRoute
export default connect(null, mapDispatchToProps)(PrivateRoute)

// export default withAuthenticator(PrivateRoute, false)
