//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

export default class Nav extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <nav className="navbar navbar-sub navbar-expand-lg bg-blue-1">
        <div className="container">
          <div
            className="d-flex flex-row align-items-center justify-content-center flex-grow-1"
            id="navbarChallenge"
            style={{ minHeight: 30 }}
          >
            <div
              className="card-title-solo white-1 text-right pr-4"
              style={{ paddingLeft: 10 }}
            >
              {this.props.link === true ? (
                <Link
                  className="app-nav-description-link"
                  activeClassName="app-nav-description-link"
                  to="/app/screener/"
                >
                  {this.props.title ? this.props.title : "Console"}
                </Link>
              ) : (
                <div className="app-nav-description-link">
                  {this.props.title ? this.props.title : "Console"}
                </div>
              )}
            </div>
            <ul className="d-flex flex-row align-items-center justify-content-center navbar-nav mr-auto">
              {/* <li className="nav-item pr-3">
                <Link
                  className="nav-link white-3"
                  activeClassName="nav-link white-2 active"
                  to="/app/screener/"
                  style={{ marginTop: -2 }}
                >
                  <span>Campaigns</span>
                </Link>
              </li> */}
              {/* <li className="nav-item pr-3">
                <Link
                  className="nav-link black-3"
                  activeClassName="nav-link black-1 active"
                  to="/about/agencies/"
                >
                  Reviews
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
