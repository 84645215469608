export function copyScreener(
  mode,
  criteria,
  landing,
  prompts,
  screener,
  survey
) {
  console.log("actions - editScreener - copyScreener - pre")
  return dispatch => {
    dispatch({
      type: "SCREENER_EDIT_COPY",
      data: { criteria, landing, prompts, screener, survey },
      mode: mode,
    })
  }
}
