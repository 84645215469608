//React
import React from "react"
import { connect } from "react-redux"

//Packages
import moment from "moment"

//Actions
import {
  getVideoCollection,
  getCampaignFilters,
  updateSelectedOptions,
} from "../../actions/getVideoCollection"

//Components
import Loading from "../loading"

//Icons
import { MdContactPhone, MdPerson } from "react-icons/md"

//Redux
const mapStateToProps = ({
  campaign,
  videosgrouped,
  filterdata,
  reportStartDate,
  reportEndDate,
  isLoading,
  isLoadingVideoMini,
}) => {
  return {
    campaign,
    videosgrouped,
    filterdata,
    reportStartDate,
    reportEndDate,
    isLoading,
    isLoadingVideoMini,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedOptions: (templateid, group, name, val) =>
    dispatch(updateSelectedOptions(templateid, group, name, val)),
  getVideoCollection: (
    templateid,
    startdate,
    enddate,
    campaignfilter,
    userfilter
  ) =>
    dispatch(
      getVideoCollection(
        templateid,
        startdate,
        enddate,
        campaignfilter,
        userfilter
      )
    ),
  getCampaignFilters: (mode, templateidarray, templateid) =>
    dispatch(getCampaignFilters(mode, templateidarray, templateid)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    console.log("listDetailScreens - componentDidMount")
    this.getTemplateMetadata()
  }
  getTemplateMetadata() {
    const { templateid, videosgrouped } = this.props

    console.log("getTemplateMetadata - templateid")
    console.log(templateid)
    console.log("getTemplateMetadata - videosgrouped")
    console.log(videosgrouped)

    let campaignsAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaigns
        ? videosgrouped[templateid].campaigns
        : []

    if (campaignsAvailToFilter && campaignsAvailToFilter.length > 0) {
      campaignsAvailToFilter = campaignsAvailToFilter.map(
        (item) => item.campaignid
      )
    }
    this.props.getCampaignFilters(
      this.props.reportmode,
      campaignsAvailToFilter,
      this.props.templateid
    )
  }
  changeOption(group, selectorname, val) {
    // const { updateSelectedOptions } = this.props
    const { reportStartDate, reportEndDate } = this.props

    console.log("changeOption - reportStartDate")
    console.log(reportStartDate)
    console.log("changeOption - reportStartDate.unix(")
    console.log(reportStartDate.unix())

    console.log("changeOption - reportEndDate")
    console.log(reportEndDate)

    // console.log("chartBarStacked - changeOption - updateSelectedOptions")
    // console.log(updateSelectedOptions)
    this.props.updateSelectedOptions(
      this.props.templateid,
      group,
      selectorname,
      val
    )
    if (group === "campaignfilter") {
      let campaignFilterAllowed = [val]
      console.log("campaignFilterAllowed")
      console.log(campaignFilterAllowed)
      this.props.getVideoCollection(
        this.props.templateid,
        reportStartDate.unix(),
        reportEndDate.unix(),
        val === "all" ? "all" : campaignFilterAllowed
      )
    }
    if (group === "userfilter") {
      let userFilterAllowed = val
      console.log("userFilterAllowed")
      console.log(userFilterAllowed)
      this.props.getVideoCollection(
        this.props.templateid,
        reportStartDate.unix(),
        reportEndDate.unix(),
        "all",
        userFilterAllowed
      )
    }
    // this.setState(prevState => ({
    //   [option]: {
    //     ...prevState[option],
    //     [suboption]: value,
    //   },
    // }))

    // this.setState(prevState => ({
    //   [option]: {
    //     ...prevState[option],
    //     [suboption]: {
    //       ...prevState[option][suboption],
    //       [suboption]: value,
    //     },
    //   },
    // }))
  }

  truncate(str, max) {
    return str.length > max ? str.substring(0, max - 3) + "..." : str
  }

  lookUpCampaignFilterMetadata(campaignid, mode, truncate) {
    const { templateid, filterdata } = this.props

    let metadata =
      filterdata && filterdata[templateid] && filterdata[templateid].data
        ? filterdata[templateid].data.find(({ id }) => id === campaignid)
        : {}

    if (mode === "push") {
      if (truncate) {
        return metadata && metadata.title
          ? this.truncate(metadata.title, 30)
          : this.truncate(`Send group: ${campaignid}`, 30)
      } else {
        return metadata && metadata.title
          ? metadata.title
          : `Send group: ${campaignid}`
      }
    }

    if (mode === "keyword")
      if (truncate) {
        return metadata && metadata.keyword
          ? this.truncate(metadata.keyword, 30)
          : this.truncate(`Keyword: ${campaignid}`, 30)
      } else {
        return metadata && metadata.keyword
          ? metadata.keyword
          : `Keyword: ${campaignid}`
      }
  }

  campaignToolbar(barOption) {
    const { templateid, videosgrouped, isLoadingVideoMini } = this.props

    console.log("campaignToolbar - videosgrouped")
    console.log(videosgrouped)

    const campaignsAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaigns
        ? videosgrouped[templateid].campaigns
        : []

    console.log("campaignsAvailToFilter")
    console.log(campaignsAvailToFilter)
    const usersAvailToFilter =
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].users
        ? videosgrouped[templateid].users
        : []

    console.log("usersAvailToFilter")
    console.log(usersAvailToFilter)
    let filterLabel
    if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].mode === "push"
    ) {
      filterLabel = "Send group"
    } else if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].mode === "keyword"
    ) {
      filterLabel = "Keyword"
    }

    let isSendgroupTab = false
    if (
      videosgrouped &&
      templateid &&
      videosgrouped[templateid] &&
      videosgrouped[templateid].campaignfilter &&
      videosgrouped[templateid].campaignfilter.campaignid
    ) {
      isSendgroupTab = true
    } else {
      if (
        videosgrouped[templateid].userfilter &&
        videosgrouped[templateid].userfilter.userfilterid &&
        videosgrouped[templateid].userfilter.userfilterid !== "all"
      ) {
        isSendgroupTab = false
      } else {
        isSendgroupTab = true
      }
    }

    const isUserTab = !isSendgroupTab

    return (
      <div class="row d-flex flex-row mb-4">
        <div class="col-md-6">
          <ul class="nav nav-tabs" id="tabSelector" role="tablist">
            <li class="nav-item">
              <a
                class={`nav-link chart-toolbar-social-title ${
                  isSendgroupTab ? "active" : ""
                }`}
                id="sendgroup-tab"
                data-toggle="tab"
                href="#sendgroup"
                role="tab"
                aria-controls="sendgroup"
                aria-selected="true"
              >
                {filterLabel}
              </a>
            </li>
            <li class="nav-item">
              <a
                class={`nav-link chart-toolbar-social-title ${
                  isUserTab ? "active" : ""
                }`}
                id="user-tab"
                data-toggle="tab"
                href="#user"
                role="tab"
                aria-controls="user"
                aria-selected="false"
              >
                User
              </a>
            </li>
          </ul>
          <div
            class="tab-content border-bottom-1-gray-6 border-left-1-gray-6 border-right-1-gray-6"
            id="filter-container"
          >
            <div
              class={`tab-pane py-2 px-2 fade ${
                isSendgroupTab ? "show active" : ""
              }`}
              id="sendgroup"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div>
                {/* <div className="chart-toolbar-social-title">{filterLabel}</div> */}

                <select
                  class="form-control form-control-sm"
                  onChange={(e) => {
                    this.changeOption(
                      "campaignfilter",
                      "campaignid",
                      e.target.value
                    )
                  }}
                  value={
                    videosgrouped &&
                    templateid &&
                    videosgrouped[templateid] &&
                    videosgrouped[templateid].campaignfilter &&
                    videosgrouped[templateid].campaignfilter.campaignid
                      ? videosgrouped[templateid].campaignfilter.campaignid
                      : "all"
                  }
                >
                  <option value="all">All</option>
                  {campaignsAvailToFilter
                    ? campaignsAvailToFilter.map((campaign) => (
                        <option value={campaign.campaignid}>
                          {this.lookUpCampaignFilterMetadata(
                            campaign.campaignid,
                            this.props.reportmode,
                            true
                          )}
                        </option>
                      ))
                    : null}
                </select>
                <div class="pt-3" style={{ width: 60 }}>
                  {isLoadingVideoMini ? <Loading class="p-2 small" /> : null}
                </div>
              </div>
            </div>
            <div
              class={`tab-pane py-2 px-2 fade ${
                isUserTab ? "show active" : ""
              }`}
              id="user"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div>
                {/* <div className="chart-toolbar-social-title">User</div> */}

                <select
                  class="form-control form-control-sm"
                  onChange={(e) => {
                    this.changeOption(
                      "userfilter",
                      "userfilterid",
                      e.target.value
                    )
                  }}
                  value={
                    videosgrouped &&
                    templateid &&
                    videosgrouped[templateid] &&
                    videosgrouped[templateid].userfilter &&
                    videosgrouped[templateid].userfilter.userfilterid
                      ? videosgrouped[templateid].userfilter.userfilterid
                      : "all"
                  }
                >
                  <option value="all">All</option>
                  {usersAvailToFilter
                    ? usersAvailToFilter.map((user) => (
                        <option value={user.phone}>
                          {user.fname && user.lname
                            ? `${user.fname} ${user.lname}`
                            : user.phone}
                        </option>
                      ))
                    : null}
                </select>
                <div class="pt-3" style={{ width: 60 }}>
                  {isLoadingVideoMini ? <Loading class="p-2 small" /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-tabs" id="tabSelectorCondition" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link chart-toolbar-social-title active"
                id="condition-tab"
                data-toggle="tab"
                href="#condition"
                role="tab"
                aria-controls="condition"
                aria-selected="true"
              >
                Condition
              </a>
            </li>
          </ul>
          <div
            class="tab-content border-bottom-1-gray-6 border-left-1-gray-6 border-right-1-gray-6"
            id="filter-container-condition"
          >
            <div
              class="tab-pane py-2 px-2 fade show active"
              id="condition"
              role="tabpanel"
              aria-labelledby="condition-tab"
            >
              {this.socialIconToolbar(barOption)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  socialIconToolbar(barOption) {
    const selectedConditions =
      this.props.videosgrouped &&
      this.props.templateid &&
      this.props.videosgrouped[this.props.templateid]
        ? this.props.videosgrouped[this.props.templateid].selected
        : []

    console.log("chartBarStacked - socialIconToolbar - videosgrouped")
    console.log(selectedConditions)

    const capitalize = (s) => {
      if (typeof s !== "string") return ""
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (
      <div className="chart-toolbar-social d-flex flex-column justify-content-center align-items-start">
        <div>
          {/* <div className="chart-toolbar-social-title">Condition</div> */}
          <div className="chart-toolbar-share d-flex flex-row flex-wrap justify-content-start align-items-center">
            {barOption.map((item, i) => {
              let icon
              return (
                <button
                  type="button"
                  onClick={() => {
                    this.changeOption(
                      "selected",
                      item.name,
                      !selectedConditions[item.name]
                    )
                  }}
                  style={{
                    borderColor: selectedConditions[item.name]
                      ? item.color
                      : "#ccc",
                  }}
                  class={`btn btn-toolbar-social ${
                    selectedConditions[item.name] ? "active" : ""
                  } d-flex flex-row justify-content-center align-items-center`}
                >
                  <span
                    class={`font-weight-light ${
                      !selectedConditions[item.name] ? "darken" : ""
                    }`}
                    style={{ color: item.color }}
                  >
                    {capitalize(item.name)}
                  </span>
                  {selectedConditions[item.name] ? (
                    <span class="pl-1" style={{ color: item.color }}>
                      &#10004;
                    </span>
                  ) : null}

                  {/* <span>&#10004;</span> */}
                  {/* {icon} */}
                  {/* {this.state.share[item.name] ? (
                <FontAwesome.FaCheck
                  size={12}
                  color="rgba(0,0,0,0.6)"
                  style={{ marginTop: 10, marginLeft: -2 }}
                />
              ) : null} */}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  lookupQuestionTitle(surveyitem) {
    const { campaign, templateid } = this.props

    console.log("lookupQuestionTitle - surveyitem")
    console.log(surveyitem)
    console.log("lookupQuestionTitle - campaign")
    console.log(campaign)

    let titleLookup =
      campaign && templateid ? campaign.find(({ id }) => id === templateid) : {}
    if (titleLookup && titleLookup.survey && titleLookup.survey.surveydata) {
      console.log("lookupQuestionTitle - titleLookup.survey.surveydata")
      console.log(titleLookup.survey.surveydata)
      titleLookup = titleLookup.survey.surveydata.find(
        ({ lang }) => lang === "en"
      )
      titleLookup = titleLookup.data.elements.find(
        ({ name }) => name === surveyitem
      )
      console.log("lookupQuestionTitle - titleLookup")
      console.log(titleLookup)
      return titleLookup && titleLookup.title && titleLookup.title.default
        ? titleLookup.title.default
        : ""
    } else {
      return ""
    }
  }
  render() {
    const { data } = this.props
    console.log("listDetailScreens - render - data")
    console.log(data)

    const capitalize = (s) => {
      if (typeof s !== "string") return ""
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    let algoNames = {}
    if (this.props.data && this.props.data[0] && this.props.data[0].algos) {
      algoNames = Object.assign({}, this.props.data[0].algos)
      delete algoNames.total
    }

    const chartColors = [
      "#b00be5",
      "#f22e62",
      "#1875d3",
      "#079dd9",
      "#07b2d9",
      "#025159",
      "#025159",
      "#03a696",
      "#03a696",
      "#66cc00",
      "#90d948",
      "#f2ce16",
      "#f27405",
      "#f24405",
      "#f20505",
    ]

    const barOption = algoNames
      ? Object.keys(algoNames).map((item, i, sourceArray) => ({
          name: item,
          color: chartColors[((i + 1) / 10 - Math.floor((i + 1) / 10)) * 10],
          icon: "FaFacebookSquare",
        }))
      : []

    console.log("((barOption))")
    console.log(barOption)

    const selectedConditions2 =
      this.props.videosgrouped &&
      this.props.templateid &&
      this.props.videosgrouped[this.props.templateid]
        ? this.props.videosgrouped[this.props.templateid].selected
        : []

    console.log("listDetailScreens - render - selectedConditions2")
    console.log(selectedConditions2)

    // const selectedConditions2Array = Object.keys(selectedConditions2).filter(key => key)
    const selectedConditions2Array = Object.keys(selectedConditions2).filter(
      (key, i) => {
        if (selectedConditions2[key] === true) {
          return key
        }
      }
    )
    console.log("listDetailScreens - render - selectedConditions2Array")
    console.log(selectedConditions2Array)

    let filteredConditionData = data.map((item) => {
      return {
        ...item,
        data: item.data.filter((subitem) =>
          selectedConditions2Array.includes(subitem.eval)
        ),
      }
    })
    console.log("listDetailScreens - render - filteredConditionData")
    console.log(filteredConditionData)

    let userPhoneList = filteredConditionData ? (
      filteredConditionData.map((item, i) =>
        item ? (
          <div class="reporting-detail-date-row" key={`date-${i}`}>
            <div class="reporting-detail-date-label bg-gray-7 round-corners mb-1">
              <a
                class="btn btn-link w-100 text-left d-flex flex-row justify-content-start align-items-center"
                data-toggle="collapse"
                href={`#row-collapse-${i}`}
                role="button"
                aria-expanded="false"
                aria-controls={`row-collapse-${i}`}
              >
                <div class="caret mr-2"></div>
                {moment(item.time).format("ddd, MMMM D YYYY")} (
                {item.data.length})
              </a>
            </div>
            <div
              class="reporting-detail-report-container multi-collapse collapse"
              id={`row-collapse-${i}`}
            >
              {item.data
                ? item.data.map((subitem, j) => (
                    <div
                      key={`row-${j}`}
                      class={`row reporting-detail-report font-weight-light  ml-4 mr-2 my-2 py-3 ${
                        j + 1 !== item.data.length
                          ? "border-bottom-1-gray-5"
                          : ""
                      }`}
                    >
                      {/* <div class="w-100">Eval: {subitem.eval}</div> */}
                      <div class="w-100 d-flex flex-row align-items-center justify-content-start mb-1">
                        <span
                          class={`badge badge-secondary mr-2`}
                          style={{
                            backgroundColor:
                              barOption &&
                              barOption.length &&
                              barOption.find(
                                (item) => item.name === subitem.eval
                              ) &&
                              barOption.find(
                                (item) => item.name === subitem.eval
                              ).color
                                ? barOption.find(
                                    (item) => item.name === subitem.eval
                                  ).color
                                : "#cccccc",
                          }}
                        >
                          {subitem.eval}
                        </span>
                        <span
                          class={`badge badge-secondary mr-2 bg-white-4 border-1-gray-4 gray-2`}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            {subitem.mode}
                          </span>
                          {subitem.campaigntitle
                            ? `: ${subitem.campaigntitle}`
                            : null}
                        </span>
                        <div class="d-flex flex-row align-items-center justify-content-start">
                          <MdContactPhone color="#666666" />
                          <small class="pl-2">{subitem.phone}</small>
                        </div>
                        {subitem.fname || subitem.lname ? (
                          <div class="d-flex flex-row align-items-center justify-content-start ml-2">
                            <MdPerson color="#666666" />
                            <small class="pl-1">
                              {subitem.fname} {subitem.lname}
                            </small>
                          </div>
                        ) : null}
                      </div>

                      {Object.keys(subitem.response).map((surveyitem, k) => (
                        <div class="w-100 mt-1 mb-2 line-height-2">
                          <span class="font-weight-light">
                            {this.lookupQuestionTitle(surveyitem)}:{" "}
                          </span>
                          <span class="font-weight-medium">
                            {/* {capitalize(surveyitem)}:{" "} */}
                            {subitem.response[surveyitem]}
                          </span>
                        </div>
                      ))}

                      {/* {JSON.stringify(subitem)} */}
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null
      )
    ) : (
      <tr>
        <td>No phone yet</td>
      </tr>
    )

    return (
      <div class="reporting-detail-container w-100">
        {this.campaignToolbar(barOption)}
        {/* {this.socialIconToolbar(barOption)} */}
        <hr size={1} />
        <div class="h5">Data</div>
        {userPhoneList ? userPhoneList : null}
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
