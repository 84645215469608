//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import { parsePhoneNumberFromString } from "libphonenumber-js"

//Actions
import { getCampaigns } from "../../actions/getCampaigns"
import { getSendGroups } from "../../actions/getSendGroups"
import { copyScreener } from "../../actions/editScreener"
import { copySendGroup } from "../../actions/editSendGroup"

//Components
import Loading from "../loading"

//Icons
import { FaChevronRight, FaArrowRight } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  company,
  campaign,
  sendgroup,
  userlogin,
  isLoading,
}) => {
  return { company, campaign, sendgroup, userlogin, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getCampaigns: cid => dispatch(getCampaigns(cid)),
  getSendGroups: () => dispatch(getSendGroups()),
  copySendGroup: (mode, deliver, sendgroup, users, templates) =>
    dispatch(copySendGroup(mode, deliver, sendgroup, users, templates)),
  copyScreener: (mode, criteria, landing, prompts, screener, survey) =>
    dispatch(copyScreener(mode, criteria, landing, prompts, screener, survey)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    if (!this.props.campaign) {
      this.props.getCampaigns()
    }
    if (!this.props.sendgroup) {
      this.props.getSendGroups()
    }
  }

  formatPhoneNumber(phone) {
    const phoneNumber = parsePhoneNumberFromString(phone)

    return phoneNumber.formatNational()
  }
  listCampaigns(timeframe) {
    const { campaign } = this.props

    console.log("home - campaign")
    console.log(campaign)

    let campaignList = campaign
      ? campaign.map((item, i) => (
          <div
            className="card w-100"
            key={`campaign-item-${i}`}
            style={{ minHeight: 50 }}
          >
            <Link to={`/app/trends/?t=${item.id}`}>
              {/* <div className="pathImageOverlay" /> */}
              <div className="container-challenge-overview w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-stretch bg-dkblue-1">
                <div className="challenge-overview-title p-3">{item.title}</div>
                {/* <div className="challenge-overview-dates">{item.fulldurationstring}</div> */}
                {/* <div className="d-flex justify-content-end"> */}
                <FaChevronRight
                  className="ml-auto"
                  color="#fff"
                  size={22}
                  style={{ opacity: 0.8 }}
                />
                {/* </div> */}
              </div>
            </Link>
          </div>
        ))
      : null

    return campaignList
  }
  listSendGroup(timeframe) {
    const { sendgroup } = this.props
    console.log("home - sendgroup")
    console.log(sendgroup)

    let sendGroupList = sendgroup
      ? sendgroup.map((item, i) => (
          <div
            className="card w-100"
            key={`campaign-item-${i}`}
            style={{ minHeight: 50 }}
          >
            <Link to={`/app/sendgroup/create/?t=${item.id}`}>
              <div className="container-challenge-overview w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-stretch bg-grayblue-1">
                <div className="challenge-overview-title p-3">{item.title}</div>
                <FaChevronRight
                  className="ml-auto"
                  color="#fff"
                  size={22}
                  style={{ opacity: 0.8 }}
                />
              </div>
            </Link>
          </div>
        ))
      : null

    return sendGroupList
  }

  render() {
    const { company, campaign, isLoading, userlogin } = this.props

    console.log("consoleHomeComponent - render - userlogin")
    console.log(userlogin)
    console.log("consoleHomeComponent - render - company")
    console.log(company)

    let numberSingular =
      company && company.numbers && company.numbers.length === 1 ? true : false
    return (
      <div className="reporting-container">
        {isLoading ? (
          <div class="p-4">
            <Loading />
          </div>
        ) : null}
        {this.props.context === "screener" ? (
          <>
            <div>{this.listCampaigns()}</div>
            <Link
              class="btn btn-dkblue btn-cta btn-block btn-lg-inline-block mt-3"
              to="/app/screener/create/"
              onClick={() => {
                this.props.copyScreener("initial")
              }}
            >
              Create new screener{" "}
              <FaArrowRight style={{ marginTop: -1 }} class="btn-arrow" />
            </Link>
          </>
        ) : null}
        {this.props.context === "sendgroup" ? (
          <>
            <div>{this.listSendGroup()}</div>
            <Link
              class="btn btn-grayblue btn-cta btn-block btn-lg-inline-block mt-3"
              to="/app/sendgroup/create/"
              onClick={() => {
                this.props.copySendGroup("initial")
              }}
            >
              Create new send group{" "}
              <FaArrowRight style={{ marginTop: -1 }} class="btn-arrow" />
            </Link>
          </>
        ) : null}
        {this.props.context === "numbers" ? (
          <>
            <p class="line-height-2 gray-1">
              For keyword-based screeners, users can initite the screener by
              sending the keyword to {numberSingular ? "this" : "one of these"}{" "}
              number
              {numberSingular ? "" : "s"}:
            </p>
            <div class="numbers-active">
              <ul>
                {company && company.numbers ? (
                  company.numbers.map(numberObj => (
                    <li>{this.formatPhoneNumber(numberObj.number)}</li>
                  ))
                ) : (
                  <li>
                    No numbers available, please contact Picohealth support
                  </li>
                )}
              </ul>
            </div>
          </>
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
