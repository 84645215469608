//React
import React from "react"
import { connect } from "react-redux"

//Actions
import { updateState } from "../../actions/createScreener"

//Icons
import { FaCheckCircle } from "react-icons/fa"

//Redux
const mapStateToProps = ({ templatecomplete }) => {
  return { templatecomplete }
}

const mapDispatchToProps = dispatch => ({
  updateState: (prop, val) => dispatch(updateState(prop, val)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let that = this
    setTimeout(() => {
      that.props.updateState(this.props.completestate, false)
    }, 5000)
  }

  render() {
    return (
      <div class="bg-dkgreen-1 round-corners d-flex flex-row align-items-center justify-cntent-center p-2 mb-2">
        <FaCheckCircle size={18} class="white-1" />
        <div class="white-1 px-2">{this.props.text}</div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
