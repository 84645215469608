//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

//Icons
import { FaRegEdit } from "react-icons/fa"
import { FiExternalLink } from "react-icons/fi"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        class={`p-2 px-3 report-header d-flex flex-row align-items-center justify-content-start ${
          this.props.bgcolor ? `bg-${this.props.bgcolor}` : ""
        }`}
      >
        <div>{this.props.text}</div>
        {this.props.templateid ? (
          <div class="ml-auto d-flex flex-row">
            <Link
              to={`/app/screener/create/?t=${this.props.templateid}`}
              class="btn btn-light btn-cta btn-sm mb-1 mx-1"
            >
              Edit
              <FaRegEdit
                style={{ marginTop: -1, marginLeft: 8 }}
                class="btn-arrow"
              />
            </Link>
            <a
              class="btn btn-light btn-cta btn-sm mb-1 mx-1"
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PRODUCTION_WELL}wellcheck/?t=${this.props.templateid}`}
            >
              Preview{" "}
              <FiExternalLink
                style={{ marginTop: -1, marginLeft: 4 }}
                class="btn-arrow"
              />
            </a>
          </div>
        ) : null}
      </div>
    )
  }
}
export default Snippet
