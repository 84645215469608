//React
import React from "react"
import { connect } from "react-redux"

//Amplify
import Amplify from "@aws-amplify/core"
import { amplifyconfig } from "../../utils/config"

//Packages
import parse from "url-parse"

//Actions
import { getCampaigns } from "../../actions/getCampaigns"
import { getSendGroups } from "../../actions/getSendGroups"
import { getCompanyDetail } from "../../actions/userManagement"

//Components
import Layout from "../../components/layout"
import NavApp from "../../components/nav/navApp"
import ReportingMenu from "../../components/appReporting/reportingMenu"
import ConsoleHome from "./consoleHomeComponent"
import TemplateComplete from "../appScreenerCreate/templateComplete"

//Redux
const mapStateToProps = ({
  company,
  templatecomplete,
  sendgroup,
  sendgroupcomplete,
}) => {
  return { company, templatecomplete, sendgroup, sendgroupcomplete }
}

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: () => dispatch(getCampaigns()),
  getSendGroups: () => dispatch(getSendGroups()),
  getCompanyDetail: (cid) => dispatch(getCompanyDetail(cid)),
})

//Configure
Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const { company, templatecomplete, sendgroupcomplete } = this.props

    console.log("consoleHome - componentDidMount - company")
    console.log(company)
    if (templatecomplete) this.props.getCampaigns()
    if (sendgroupcomplete) this.props.getSendGroups()
    if (
      company.constructor === Object &&
      Object.entries(company).length === 0
    ) {
      //if company has no object entries
      console.log("**getting company")
      this.props.getCompanyDetail()
    } else {
      console.log("**NOT getting company")
    }
  }

  render() {
    console.log("((this.state))")
    console.log(this.state)

    const url = parse(window.location.href, true)
    const pathname = url.pathname

    const menuData = [
      {
        header: "Menu",
        items: [
          {
            title: "Screeners",
            url: "/app/screener/",
            passtemplate: false,
          },
          {
            title: "Send groups",
            url: "/app/sendgroup/",
            passtemplate: false,
          },
          {
            title: "Numbers",
            url: "/app/numbers/",
            passtemplate: false,
            access: [{ templateparam: "numbers", templatevalue: "exists" }],
          },
        ],
      },
    ]

    const { templatecomplete, sendgroupcomplete } = this.props

    return (
      <Layout>
        <div class="flex-grow-1">
          {/* <AppNav logo={this.state.company && this.state.company.companylogo ? this.state.company.companylogo : ''} /> */}
          <NavApp />

          <div class="container container-app container-page container-reporting">
            <div class="row">
              <div class="col-md-3">
                <ReportingMenu menudata={menuData} />
              </div>
              <div class="col-md-9">
                {sendgroupcomplete ? (
                  <TemplateComplete
                    text="Send group updated"
                    completestate="sendgroupcomplete"
                  />
                ) : null}
                {templatecomplete ? (
                  <TemplateComplete
                    text="Screener updated"
                    completestate="templatecomplete"
                  />
                ) : null}

                {pathname === "/app/screener/" ? (
                  <>
                    <h3 class="pb-2 mt-4 mt-md-0">Active screeners</h3>
                    <ConsoleHome context="screener" />
                  </>
                ) : null}
                {pathname === "/app/sendgroup/" ? (
                  <>
                    <h3 class="pb-2 mt-4 mt-md-0">Active send groups</h3>
                    <ConsoleHome context="sendgroup" />
                  </>
                ) : null}
                {pathname === "/app/numbers/" ? (
                  <>
                    <h3 class="pb-2 mt-4 mt-md-0">Active numbers</h3>
                    <ConsoleHome context="numbers" />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// export default App
// export default connect(mapStateToProps, null)(App)
export default connect(mapStateToProps, mapDispatchToProps)(App)
