//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import parse from "url-parse"

//Actions
import {
  updateSendGroupFieldDirect,
  createSendGroup,
} from "../../actions/createSendGroup"
import { getSendGroups, getUserOptout } from "../../actions/getSendGroups"
import { copySendGroup } from "../../actions/editSendGroup"

//Components
import Loading from "../loading"
import AppSendGroupUserForm from "./appSendGroupUserForm"
import AppSendGroupBaseInfo from "./appSendGroupBaseInfo"

//Icons
import { FaSpinner } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  sendgroup,
  sendgroupcreate,
  isLoadingSendGroupCreate,
  isLoading,
}) => {
  return {
    sendgroup,
    sendgroupcreate,
    isLoadingSendGroupCreate,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSendGroupFieldDirect: (prop, val, mode) =>
    dispatch(updateSendGroupFieldDirect(prop, val, mode)),
  createSendGroup: (deliver, sendgroup, users, templates, id) =>
    dispatch(createSendGroup(deliver, sendgroup, users, templates, id)),
  copySendGroup: (mode, deliver, sendgroup, users, templates) =>
    dispatch(copySendGroup(mode, deliver, sendgroup, users, templates)),
  getSendGroups: () => dispatch(getSendGroups()),
  getUserOptout: (sendgroupid) => dispatch(getUserOptout(sendgroupid)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showErrorLink: false }
  }
  componentDidMount() {
    const { sendgroup } = this.props

    const url = parse(window.location.href, true)

    console.log("appSendGroupCreate - url")
    console.log(url)
    if (
      url &&
      url.query &&
      url.query.t &&
      (!sendgroup || sendgroup.length === 0)
    ) {
      this.setState({ showErrorLink: true })
    } else if (url && url.query && url.query.t) {
      this.copySendGroup(url.query.t)
      this.props.getUserOptout(url.query.t)
    }
  }

  copySendGroup(targetId) {
    const { sendgroup } = this.props
    const targetSendGroup = sendgroup.find(({ id }) => id === targetId)
    console.log("appSendGroupCreate - targetSendGroup")
    console.log(targetSendGroup)
    this.props.copySendGroup(
      "copy",
      targetSendGroup.deliver,
      { name: targetSendGroup.title },
      targetSendGroup.users,
      targetSendGroup.templates
    )
  }

  createSendGroup = () => {
    const { sendgroupcreate } = this.props
    const url = parse(window.location.href, true)
    const existingId = url && url.query && url.query.t ? url.query.t : null

    if (!sendgroupcreate.sendgroup.name) {
      this.updateSendGroupFieldDirect(
        "error",
        "sendgroupname",
        "Please include a name for this group"
      )
      return
    } else {
      this.updateSendGroupFieldDirect("error", "sendgroupname", false)
    }

    if (sendgroupcreate.users.length === 0) {
      this.updateSendGroupFieldDirect(
        "error",
        "sendgroupuserslength",
        "Please include at least one recipient in the group"
      )
      return
    } else {
      this.updateSendGroupFieldDirect("error", "sendgroupuserslength", false)
    }

    let usersContainsError = false
    let usersContainsDuplicateError = false
    let usersError = []

    //Find duplicate numbers if any

    //Find phone numbers that are duplicates, see https://stackoverflow.com/a/53212154
    const lookup = sendgroupcreate.users.reduce((accum, user) => {
      accum[user.mobile.format] = ++accum[user.mobile.format] || 0
      return accum
    }, {})

    let duplicatePhoneNumbers = sendgroupcreate.users.filter(
      (user) => lookup[user.mobile.format]
    )
    //de-duplicate the list
    duplicatePhoneNumbers = Array.from(
      new Set(duplicatePhoneNumbers.map((user) => user.mobile.phone))
    ).map((id) => {
      return duplicatePhoneNumbers.find((user) => user.mobile.phone === id)
    })

    //flatten array
    duplicatePhoneNumbers = duplicatePhoneNumbers.map(
      (user) => user.mobile.format
    )

    //set flags
    sendgroupcreate.users.forEach((user, i) => {
      let mobileError = {}
      if (!user.mobile.format) {
        mobileError.format = true
        usersContainsError = true
      } else {
        mobileError.format = false
      }
      if (user.mobile.valid === false) {
        mobileError.valid = true
        usersContainsError = true
      } else {
        mobileError.valid = false
      }
      if (duplicatePhoneNumbers.includes(user.mobile.format)) {
        mobileError.duplicate = true
        usersContainsError = true
        usersContainsDuplicateError = true
      } else {
        mobileError.duplicate = false
      }

      usersError.push({ mobile: mobileError })
    }) //end sendgroupcreate.users foreach

    this.updateSendGroupFieldDirect("error", "sendgroupusersitems", usersError)

    if (usersContainsError === true) {
      this.updateSendGroupFieldDirect(
        "error",
        "sendgroupuserserror",
        `Please fix errors with the recipients${
          usersContainsDuplicateError ? ", including removing duplicates." : ""
        }`
      )
      return
    } else {
      this.updateSendGroupFieldDirect("error", "sendgroupuserserror", false)
    }

    this.props.createSendGroup(
      sendgroupcreate.deliver,
      sendgroupcreate.sendgroup,
      sendgroupcreate.users,
      sendgroupcreate.templates,
      existingId
    )
  }

  updateSendGroupFieldDirect = (group, prop, val) => {
    this.props.updateSendGroupFieldDirect(group, prop, val)
  }

  render() {
    const {
      sendgroup,
      sendgroupcreate,
      isLoading,
      isLoadingSendGroupCreate,
    } = this.props
    const { templateid } = this.state

    console.log("appSendGroupCreate - sendgroup")
    console.log(sendgroup)
    console.log("appSendGroupCreate - sendgroupcreate")
    console.log(sendgroupcreate)

    return (
      <div class="app-sendgroup-create-container">
        {this.state.showErrorLink ? (
          <div class="d-flex flex-column">
            <div class="p-4">
              We weren't able to load the list of send groups. Tap the link
              below to reload active send groups.
            </div>
            <Link class="btn btn-grayblue btn-cta" to="/app/sendgroup/">
              Reload
            </Link>
          </div>
        ) : (
          <form class="form-sendgroup-group p-2 needs-validation" novalidate>
            <AppSendGroupBaseInfo />
            <AppSendGroupUserForm />
            <div class="mt-5 py-3 border-top-1-gray-6 d-flex flex-row align-items-center justify-content-end">
              <button
                type="button"
                class="btn btn-dkblue mx-2 d-flex flex-row align-items-center justify-content-end"
                onClick={(e) => {
                  this.createSendGroup()
                }}
              >
                Save
                {isLoadingSendGroupCreate ? (
                  <div className="pl-2">
                    <FaSpinner size={16} className="icon-spin" />
                  </div>
                ) : null}
              </button>
            </div>
          </form>
        )}
      </div>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
