//React
import React from "react"
import { connect } from "react-redux"

//Actions
import { advanceScreenerForm } from "../../actions/createScreener"

//Redux
const mapStateToProps = ({ templateposition }) => {
  return {
    templateposition,
  }
}

const mapDispatchToProps = (dispatch) => ({
  advanceScreenerForm: (pos) => dispatch(advanceScreenerForm(pos)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { templateposition } = this.props

    return (
      <div class="app-screener-create-position mb-4 d-flex flex-row align-items-center justify-content-center">
        <div
          // type="button"
          class={`p-2 mx-3 app-screener-position-container ${
            templateposition === 1 ? "active" : ""
          } d-flex flex-column align-items-center justify-content-center`}
          // onClick={() => {
          //   this.props.advanceScreenerForm(1)
          // }}
        >
          <div class="circle font-weight-bold d-flex flex-row align-items-center justify-content-center">
            1
          </div>
          <small class="mt-2 line-height-0">Screener details</small>
        </div>
        <div
          // type="button"
          class={`p-2 mx-3 app-screener-position-container ${
            templateposition === 2 ? "active" : ""
          } d-flex flex-column align-items-center justify-content-center`}
          // onClick={() => {
          //   this.props.advanceScreenerForm(2)
          // }}
        >
          <div class="circle font-weight-bold d-flex flex-row align-items-center justify-content-center">
            2
          </div>
          <small class="mt-2 line-height-0">Landing page</small>
        </div>
        <div
          // type="button"
          class={`p-2 mx-3 app-screener-position-container ${
            templateposition === 3 ? "active" : ""
          } d-flex flex-column align-items-center justify-content-center`}
          // onClick={() => {
          //   this.props.advanceScreenerForm(3)
          // }}
        >
          <div class="circle font-weight-bold d-flex flex-row align-items-center justify-content-center">
            3
          </div>
          <small class="mt-2 line-height-0">Screener questions</small>
        </div>
        <div
          // type="button"
          class={`p-2 mx-3 app-screener-position-container ${
            templateposition === 4 ? "active" : ""
          } d-flex flex-column align-items-center justify-content-center`}
          // onClick={() => {
          //   this.props.advanceScreenerForm(4)
          // }}
        >
          <div class="circle font-weight-bold d-flex flex-row align-items-center justify-content-center">
            4
          </div>
          <small class="mt-2 line-height-0">Evaluation criteria</small>
        </div>
      </div>
    )
  }
}
// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
