//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import parse from "url-parse"

//Redux
const mapStateToProps = ({ company }) => {
  return { company }
}

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    this.setState({ scrolled: "chatbot" })
  }
  checkAccess(menuitem) {
    //see if access value is in company
    const { company } = this.props

    if (!menuitem.access) {
      return true
    } else if (
      menuitem.access.some((accessitem) => {
        if (accessitem.templateparam.includes("/")) {
          const splitString = accessitem.templateparam.split("/")
          console.log("{{splitString}}")
          console.log(splitString)

          if (
            Object.entries(company).length !== 0 &&
            company.constructor === Object
          ) {
            //check if empty
            console.log("company")
            console.log(company)
            let nestedObject = company
            splitString.forEach((item) => {
              nestedObject = nestedObject[item]
                ? nestedObject[item]
                : nestedObject
            })
            console.log("[[[[[[nestedObject]]]]]]")
            console.log(nestedObject)
            if (accessitem.templatevalue === "exists") {
              if (nestedObject.length > 0) {
                return true
              } else {
                return false
              }
            } else {
              return accessitem.templatevalue === nestedObject
            }
          } else {
            return false
          }
        } else {
          if (accessitem.templatevalue === "exists") {
            if (
              company &&
              company[accessitem.templateparam] &&
              company[accessitem.templateparam].length &&
              company[accessitem.templateparam].length > 0
            ) {
              return true
            } else {
              return false
            }
          } else {
            return (
              accessitem.templatevalue === company[accessitem.templateparam]
            )
          }
        }
      })
    ) {
      return true
    }
    return false
  }
  render() {
    const url = parse(window.location.href, true)

    console.log("menu === - url")
    console.log(url)
    console.log("this.props.menudata")
    console.log(this.props.menudata)
    console.log("[[[[[this.props.company]]]]]")
    console.log(this.props.company)

    return (
      <div class="feature-menu-container">
        {this.props.menudata.map((item, i) => (
          <div>
            {this.checkAccess(item) ? (
              <div class="feature-menu-subcontainer">
                <div class="feature-menu">
                  <div class="feature-menu-header card-title-solo bg-blue-1 white-1">
                    {item.header}
                  </div>

                  {item.items.map((subitem, j) => (
                    <>
                      {this.checkAccess(subitem) ? (
                        <li
                          class={
                            url.pathname === subitem.url ? "menu-active" : ""
                          }
                        >
                          <Link
                            to={`${subitem.url}${
                              subitem.passtemplate === true
                                ? `?t=${this.props.templateid}`
                                : ""
                            }`}
                            class="feature-menu-link scroll"
                          >
                            <span class="black-3">{subitem.title}</span>
                          </Link>
                        </li>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, null)(Snippet)
