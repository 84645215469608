// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import moment from "moment"

import { connect } from "react-redux"
import { getVideoCollection } from "../../actions/getVideoCollection"
import ChartBarStacked from "./chartBarStacked"
import ListDetailScreens from "./listDetailScreens"

import LabelTotal from "./labelTotal"
import Loading from "../loading"

import parse from "url-parse"

const mapStateToProps = ({
  users,
  videosgrouped,
  campaign,
  isLoading,
  isLoadingVideoCreatedGrouped,
  isLoadingVideoUsers,
  reportStartDate,
  reportEndDate,
}) => {
  return {
    users,
    videosgrouped,
    campaign,
    isLoading,
    isLoadingVideoCreatedGrouped,
    isLoadingVideoUsers,
    reportStartDate,
    reportEndDate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVideoCollection: (templateid, startdate, enddate, campaignfilter) =>
    dispatch(
      getVideoCollection(templateid, startdate, enddate, campaignfilter)
    ),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    console.log("reportingVideoCreation - componentDidMount - url")
    console.log(url)
    this.setState({ templateid: url.query.t })

    const todayMoment = moment().endOf("day")
    const lastMonthMoment = moment().subtract(2, "days").startOf("day")

    const { reportStartDate, reportEndDate } = this.props

    let startDate = reportStartDate
      ? reportStartDate.unix()
      : lastMonthMoment.unix()
    let endDate = reportEndDate ? reportEndDate.unix() : todayMoment.unix()

    if (
      url.query &&
      url.query.t &&
      (!this.props.videosgrouped ||
        (this.props.videosgrouped && !this.props.videosgrouped[url.query.t]))
    ) {
      console.log("calling videocollection API *** videocreation")
      this.props.getVideoCollection(url.query.t, startDate, endDate)
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  render() {
    const {
      videosgrouped,
      users,
      campaign,
      isLoading,
      isLoadingVideoCreatedGrouped,
      isLoadingVideoUsers,
    } = this.props
    const { templateid } = this.state
    console.log("videosgrouped")
    console.log(videosgrouped)
    console.log("this.state")
    console.log(this.state)
    console.log("campaign - creation - props")
    console.log(campaign)

    const url = parse(window.location.href, true)

    const userData =
      users && templateid && users[templateid] ? users[templateid].screens : []
    const chartData =
      videosgrouped && templateid && videosgrouped[templateid]
        ? videosgrouped[templateid].data
        : []
    const chartLabels =
      videosgrouped && templateid && videosgrouped[templateid]
        ? videosgrouped[templateid].labels
        : []

    const videoBounds =
      videosgrouped && templateid && videosgrouped[templateid]
        ? videosgrouped[templateid].bounds
        : []

    const reportMode =
      videosgrouped && templateid && videosgrouped[templateid]
        ? videosgrouped[templateid].mode
        : ""

    return (
      <div class="reporting-container">
        {isLoadingVideoCreatedGrouped || isLoadingVideoUsers ? (
          <div class="p-3">
            <Loading />
          </div>
        ) : null}
        {!isLoadingVideoCreatedGrouped &&
        !isLoadingVideoUsers &&
        videosgrouped &&
        templateid &&
        url.pathname === "/app/trends/" ? (
          <div>
            <LabelTotal
              videosgrouped={videosgrouped}
              // data={videoTotal}
              text="screens started"
              singularText="screen started"
              bounds={videoBounds}
              templateid={templateid}
            />
            <div class="d-flex flex-row align-items-center justify-content-start mt-2">
              {chartData.length > 0 ? (
                <ChartBarStacked
                  reportmode={reportMode}
                  templateid={templateid}
                  ticks={chartLabels}
                  data={chartData}
                  axisLabels={{ y: "# of screens" }}
                />
              ) : (
                <div>No data to display</div>
              )}
            </div>
          </div>
        ) : null}
        {!isLoadingVideoCreatedGrouped &&
        !isLoadingVideoUsers &&
        videosgrouped &&
        templateid &&
        url.pathname === "/app/detail/" ? (
          <div>
            <LabelTotal
              videosgrouped={videosgrouped}
              // data={videoTotal}
              text="screens started"
              singularText="screen started"
              bounds={videoBounds}
              templateid={templateid}
            />
            <div class="d-flex flex-row align-items-center justify-content-start mt-2">
              {chartData.length > 0 ? (
                <ListDetailScreens
                  reportmode={reportMode}
                  templateid={templateid}
                  data={userData}
                />
              ) : (
                <div>No data to display</div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
