//React
import React from "react"

//Components
import Loading from "../loading"
import ProgressBar from "../elements/progressBar"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: 0,
    }
  }
  componentDidMount() {
    this.updateTime()
  }

  updateTime() {
    if (this.state.progress < 100) {
      this.setState(
        prevState => ({
          progress: prevState.progress + 1,
        }),
        () => {
          setTimeout(() => {
            this.updateTime()
          }, 100)
        }
      )
    }
  }
  render() {
    return (
      <div class="text-center">
        {this.state.progress < 100 ? (
          <div>
            <div class="p-2">Uploading logo image</div>
            <ProgressBar fileloaded={this.state.progress} />
          </div>
        ) : (
          <div>
            <div class="p-2">Saving new screener</div>
            <Loading />
          </div>
        )}
      </div>
    )
  }
}

export default Snippet
