import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"

export function updateState(prop, val) {
  return (dispatch) => {
    dispatch({
      type: "SET_STATE",
      data: { prop, val },
    })
  }
}

export function updateLandingdataOption(prop, val, lang) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_LANDING_LANG",
      data: { prop, val, lang },
    })
  }
}
export function updateDeliverOption(prop, val, marker, lang) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_DELIVER_LANG",
      data: { prop, val, marker, lang },
    })
  }
}
export function updateScreenerField(group, marker, prop, val, lang) {
  if (lang) {
    return (dispatch) => {
      dispatch({
        type: "TEMPLATE_CREATE_UPDATE_FORM_FIELD_LANG",
        data: { group, marker, prop, val, lang },
      })
    }
  } else {
    return (dispatch) => {
      dispatch({
        type: "TEMPLATE_CREATE_UPDATE_FORM_FIELD",
        data: { group, marker, prop, val },
      })
    }
  }
}
export function updatePrompts(prop, val, marker, lang) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_PROMPTS_LANG",
      data: { prop, val, marker, lang },
    })
  }
}
export function updateScreenerFieldDirect(group, prop, val) {
  console.log(
    "actions - createScreener - updateScreenerFieldDirect - pre dispatch"
  )
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_FORM_FIELD_OBJ",
      data: { group, prop, val },
    })
  }
}
export function updateScreenerOption(
  mode,
  formoption,
  marker,
  pos,
  val,
  lang,
  prop
) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_FORM_OPTION",
      data: { formoption, marker, pos, val, lang, prop },
      mode: mode,
    })
  }
}
export function removeScreenerItem(prop, pos) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_REMOVE_ITEM",
      data: { prop, pos },
    })
  }
}
export function updateScreenerForm(prop, val, mode) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_FORM",
      data: { prop, val },
      mode: mode || "",
    })
  }
}

export function advanceScreenerForm(pos) {
  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_POSITION",
      data: pos,
    })
  }
}

export function checkKeyword(keyword) {
  console.log("actions - checkKeyword - keyword")
  console.log(keyword)
  let apiName = "Picohealthweb"

  let path = "admin/template/check"

  let cid = Cache.getItem("cid")

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: "LOADING_START_TEMPLATE_CHECK",
      })
      Auth.currentAuthenticatedUser()
        .then((user) => {
          //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
          console.log("actions - checkKeyword - auth - user")
          console.log(user)
          let jwtToken = user.signInUserSession.accessToken.jwtToken
          let options = {
            headers: {
              Authorization: jwtToken,
            },
            queryStringParameters: {
              k: keyword,
              cid,
            },
          }

          //begin promise
          API.get(apiName, path, options)
            .then((response) => {
              console.log("actions - createScreener - checkKeyword - then")
              console.log(response)
              const data = response || {}

              console.log("actions - createScreener - checkKeyword - data")
              console.log(data)

              let result = data.avail
              if (result === true) {
                console.log(
                  "actions - createScreener - checkKeyword - updateScreenerFieldDirect - pre"
                )
                // updateScreenerFieldDirect("error", "screenerkeyword", false)
                //weird that you cant call the function directly and have it work
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_FORM_FIELD_OBJ",
                  data: {
                    group: "error",
                    prop: "screenerkeyword",
                    val: false,
                  },
                })
              }

              dispatch({
                type: "TEMPLATE_CREATE_KEYWORD_CHECK",
                data: result,
              })
              resolve(result)
            })
            .catch((error) => {
              console.log("actions - createScreener - checkKeyword - catch")
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        }) //end get Auth token
    }) //end return promise
  } //end return dispatch
}

export function createScreener(
  criteria,
  landing,
  prompts,
  screener,
  survey,
  id
) {
  console.log("actions - createScreener - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createScreener - cid")
  console.log(cid)

  let apiName = "Picohealthweb"

  let path = "admin/screener/create"

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createScreener - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            criteria,
            landing,
            prompts,
            screener,
            survey,
            cid,
            id,
          },
        }

        console.log(
          "actions - createScreener - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createScreener - API.post - result")
              console.log(result)

              navigate("/app/screener/")

              return resolve(
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_COMPLETE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
