import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

export function getSendGroups() {
  let cid = Cache.getItem("cid")

  console.log("actions - getSendGroups - cid")
  console.log(cid)

  let apiName = "Picohealthweb"
  let path = "admin/sendgroup/list"

  return (dispatch) => {
    dispatch({
      type: "LOADING_SENDGROUP_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - getSendGroups - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        // let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid: cid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - getSendGroups - API.get - data")
              console.log(data)

              let result = data.map((item, i) => ({
                id: item.id,
                title: item.title,
                datecreated: item.datecreated,
                deliver: JSON.parse(item.deliver),
                users: JSON.parse(item.users),
                templates: item.templates ? JSON.parse(item.templates) : [],
              }))
              console.log("actions - getCampaigns - API.get - result")
              console.log(result)

              //Sort with latest send group on top
              result.sort((a, b) => (a.datecreated > b.datecreated ? 1 : -1))

              return resolve(
                dispatch({
                  type: "SENDGROUP_REPLACE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}

export function getUserOptout(sendgroupid) {
  let cid = Cache.getItem("cid")

  console.log("actions - getUserOptout - cid")
  console.log(cid)

  let apiName = "Picohealthweb"
  let path = "admin/sendgroup/optout"

  return (dispatch) => {
    dispatch({
      type: "LOADING_SENDGROUP_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - getSendGroups - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        // let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid: cid,
            sid: sendgroupid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - getSendGroups - API.get - data")
              console.log(data)

              // let result = data.map((item, i) => ({
              //   id: item.id,
              //   title: item.title,
              //   datecreated: item.datecreated,
              //   deliver: JSON.parse(item.deliver),
              //   users: JSON.parse(item.users),
              //   templates: item.templates ? JSON.parse(item.templates) : [],
              // }))
              // console.log("actions - getCampaigns - API.get - result")
              // console.log(result)

              // //Sort with latest send group on top
              // result.sort((a, b) => (a.datecreated > b.datecreated ? 1 : -1))

              return resolve(
                dispatch({
                  type: "SENDGROUP_UPDATE_DIRECT_OBJ",
                  data: { group: "subscription", prop: "status", val: data },
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
