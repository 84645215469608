//React
import React from "react"
import { connect } from "react-redux"

//Packages
import * as moment from "moment"
import "moment-timezone"
import "react-dates/initialize"
import { DateRangePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"

//Actions
import {
  getVideoCollection,
  updateState,
} from "../../actions/getVideoCollection"

const mapStateToProps = ({ reportStartDate, reportEndDate }) => {
  return {
    reportStartDate,
    reportEndDate,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateState: (prop, val) => dispatch(updateState(prop, val)),
  getVideoCollection: (templateid, startdate, enddate, campaignfilter) =>
    dispatch(
      getVideoCollection(templateid, startdate, enddate, campaignfilter)
    ),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    let focusedInput = null
    if (props.autoFocus) {
      focusedInput = START_DATE
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE
    }
    this.state = {
      focusedInput,
    }
    this.onFocusChange = this.onFocusChange.bind(this)
  }
  componentDidMount() {
    const todayMoment = moment().endOf("day")
    const lastMonthMoment = moment().subtract(2, "days").startOf("day")

    const { reportStartDate, reportEndDate } = this.props

    if (!reportStartDate)
      this.props.updateState("reportStartDate", lastMonthMoment)
    if (!reportEndDate) this.props.updateState("reportEndDate", todayMoment)

    console.log("componentDidMount - reportStartDate")
    console.log(reportStartDate)
    console.log("componentDidMount - reportEndDate")
    console.log(reportEndDate)
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput })
  }
  updateDates(startDate, endDate) {
    console.log("---updateDates ---")
    console.log("startDate")
    console.log(startDate)
    console.log("endDate")
    console.log(endDate)
    let startDateUpdated = startDate
      ? startDate.clone().startOf("day")
      : startDate
    let endDateUpdated = endDate ? endDate.clone().endOf("day") : endDate
    console.log("---updateDates - updated---")
    console.log("startDateUpdated")
    console.log(startDateUpdated)
    console.log("endDateUpdated")
    console.log(endDateUpdated)
    this.props.updateState("reportStartDate", startDateUpdated)
    this.props.updateState("reportEndDate", endDateUpdated)
  }
  getNewData(startDate, endDate) {
    if (startDate && endDate) {
      console.log("getData - endDate.clone().startOf().unix()")
      console.log(startDate.clone().startOf("day").unix())
      console.log("getData - endDate.clone().endOf().unix()")
      console.log(endDate.clone().endOf("day").unix())
      this.props.getVideoCollection(
        this.props.templateid,
        startDate.clone().startOf("day").unix(),
        endDate.clone().endOf("day").unix()
      )
    }
  }
  render() {
    const { reportStartDate, reportEndDate } = this.props

    // const videoTotal =
    //   this.props.videosgrouped &&
    //   this.props.templateid &&
    //   this.props.videosgrouped[this.props.templateid]
    //     ? this.props.videosgrouped[this.props.templateid].total
    //     : 0
    let videoTotal = 0
    if (
      this.props.videosgrouped &&
      this.props.templateid &&
      this.props.videosgrouped[this.props.templateid] &&
      this.props.videosgrouped[this.props.templateid].selected &&
      this.props.videosgrouped[this.props.templateid].totalbyalgo
    ) {
      console.log("object-keys")
      console.log(
        Object.keys(this.props.videosgrouped[this.props.templateid].totalbyalgo)
      )
      videoTotal = Object.keys(
        this.props.videosgrouped[this.props.templateid].totalbyalgo
      ).reduce((accum, algo, i, sourceArray) => {
        console.log("algo")
        console.log(algo)
        if (this.props.videosgrouped[this.props.templateid].selected[algo]) {
          accum =
            accum +
            this.props.videosgrouped[this.props.templateid].totalbyalgo[algo]
        }
        return accum
      }, 0)
    }

    const minDateDay =
      this.props.bounds && this.props.bounds.min
        ? moment.unix(this.props.bounds.min / 1000).format("D")
        : ""
    const maxDateDay =
      this.props.bounds && this.props.bounds.max
        ? moment.unix(this.props.bounds.max / 1000).format("D")
        : ""
    const minDateMonth =
      this.props.bounds && this.props.bounds.min
        ? moment.unix(this.props.bounds.min / 1000).format("MM")
        : ""
    const maxDateMonth =
      this.props.bounds && this.props.bounds.max
        ? moment.unix(this.props.bounds.max / 1000).format("MM")
        : ""

    let dateRange
    if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth === maxDateMonth &&
      minDateDay === maxDateDay
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D, YYYY")}`
    } else if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth === maxDateMonth
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D")} - ${moment
        .unix(this.props.bounds.max / 1000)
        .format("D, YYYY")}`
    } else if (
      this.props.bounds &&
      this.props.bounds.min &&
      this.props.bounds.max &&
      minDateMonth !== maxDateMonth
    ) {
      dateRange = `${moment
        .unix(this.props.bounds.min / 1000)
        .format("MMM. D")} - ${moment
        .unix(this.props.bounds.max / 1000)
        .format("MMM. D, YYYY")}`
    }

    // const minDate = this.props.bounds.min
    //   ? moment.unix(this.props.bounds.min / 1000).format("MM/DD/YYYY")
    //   : ""
    // const maxDate = this.props.bounds.max
    //   ? moment.unix(this.props.bounds.max / 1000).format("MM/DD/YYYY")
    //   : ""
    return (
      <div class="report-total-container d-flex flex-row align-items-center ">
        <div class="report-total-title d-flex flex-row justify-content-center align-items-center">
          {videoTotal ? videoTotal : 0}
        </div>
        <div class="report-total-label d-flex flex-row justify-content-start align-items-center ml-2">
          {videoTotal === 1 && this.props.singularText
            ? this.props.singularText
            : this.props.text}
        </div>
        {/* {reportStartDate ? reportStartDate : "00"}||
        {reportEndDate ? reportEndDate : "00"} */}
        <div class="report-total-date-range d-flex flex-grow-1 flex-column justify-content-center align-items-end text-right">
          {/* {dateRange} */}
          <DateRangePicker
            startDate={reportStartDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={reportEndDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              this.updateDates(startDate, endDate)
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            onClose={({ startDate, endDate }) => {
              this.getNewData(startDate, endDate)
            }}
            isOutsideRange={() => false}
            hideKeyboardShortcutsPanel={true}
            minimumNights={0}
          />
        </div>
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
