import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { groupBy } from "./groupBy"
import moment from "moment"

export function updateState(prop, val) {
  return (dispatch) => {
    dispatch({
      type: "SET_STATE",
      data: { prop, val },
    })
  }
}

export function updateSelectedOptions(templateid, group, name, val) {
  return (dispatch) => {
    dispatch({
      type: "VIDEOS_UPDATE_SELECTED_OPTIONS",
      data: { templateid, group, name, val },
    })
  }
}

export function getVideoCollection(
  templateid,
  startdate,
  enddate,
  campaignfilter,
  userfilter
) {
  let cid = Cache.getItem("cid")

  console.log("actions - createScreener - cid")
  console.log(cid)

  let apiName = "Picohealthweb"

  let path = "admin/screener/data"

  function getMinTime(data) {
    return data.reduce((min, p) => (p.time < min ? p.time : min), data[0].time)
  }
  function getMaxTime(data) {
    return data.reduce((max, p) => (p.time > max ? p.time : max), data[0].time)
  }
  function getAlgoNames(data, groupedResults, key) {
    return data.algos.reduce(
      (accum, algo, i) => {
        let val = groupedResults[key].filter((item) => item.eval === algo)
          .length
        // accum[algo] = val
        accum[algo] = {
          campaigns: getCampaignNames(groupedResults, key, algo),
          total: val,
        }
        accum.total += val
        return accum
      },
      { total: 0 }
    )
  }

  function getCampaignNames(data, key, algo) {
    let uniqueCampaigns = []

    if (key) {
      uniqueCampaigns = [...new Set(data[key].map((item) => item.campaignid))]
    } else {
      uniqueCampaigns = [...new Set(data.map((item) => item.campaignid))]
    }
    // return uniqueCampaigns.map(campaign => ({
    //   id: campaign,
    //   count: groupedResults[key].filter(item => item.campaignid === campaign),
    // }))

    if (key && algo) {
      return uniqueCampaigns.reduce((accum, campaign, i) => {
        accum[campaign] = {
          total: data[key].filter(
            (item) => item.campaignid === campaign && item.eval === algo
          ).length,
        }
        return accum
      }, {})
    } else {
      return uniqueCampaigns.map((campaign) => ({
        campaignid: campaign,
        campaignname: "",
      }))
    }
  }

  return (dispatch) => {
    if (campaignfilter) {
      dispatch({
        type: "LOADING_START_SCREENER_DATA_MINI",
      })
    } else {
      dispatch({
        type: "LOADING_START_SCREENER_DATA",
      })
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("actions - getVideoCollection - auth - user")
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            t: templateid,
            s: startdate,
            e: enddate,
            cid: cid,
            // s: user.attributes.sub,
          },
        }

        if (userfilter && userfilter !== "all") {
          console.log("###userfilter")
          console.log(userfilter)
          // results = results.filter((item) => item.phone === userfilter)
          options.queryStringParameters.u = userfilter
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              console.log("actions - getVideoCollection - then")
              console.log(response)
              const data = response || {}
              const usersResultContainer = {}
              const videoResultContainer = {}
              const reviewsResultContainer = {}

              console.log("actions - getVideoCollection - data")
              console.log(data)

              if (data.screens.length === 0) {
                usersResultContainer[templateid] = {
                  response: [],
                  phone: [],
                }

                videoResultContainer[templateid] = {
                  selected: [],
                  labels: [],
                  data: [],
                  total: 0,
                  bounds: {
                    max: 0,
                    min: 0,
                  },
                }
                reviewsResultContainer[templateid] = []

                return resolve(
                  dispatch({
                    type: "VIDEOS_CREATED_GROUPED_REPLACE",
                    data: videoResultContainer,
                  }),
                  dispatch({
                    type: "VIDEOS_USERS_REPLACE",
                    data: usersResultContainer,
                  })
                  // dispatch({
                  //   type: "REVIEWS_REPLACE",
                  //   data: reviewsResultContainer,
                  // })
                )
              } //end data.length

              let results = data.screens.map((item, i) => ({
                id: item.id,
                campaignid: item.campaignid,
                campaigntitle: item.campaigntitle,
                fname: item.fname || "",
                lname: item.lname || "",
                mode: item.mode,
                // sessionid: item.sessionid,
                // email: item.email,
                phone: item.phone || "",
                // emailconfirm: item.emailconfirm,
                // smsconfirm: item.smsconfirm,
                datecreated: item.datecreated,
                response: item.response ? JSON.parse(item.response) : {},
                // tags: item.tags,
                eval: item.eval || "incomplete",
              }))

              if (campaignfilter && campaignfilter !== "all") {
                console.log("###campaignfilter")
                console.log(campaignfilter)
                results = results.filter((item) =>
                  campaignfilter.includes(item.campaignid)
                )
              }

              console.log("***results")
              console.log(results)
              //TODO: filter results by completion status?

              data.campaigns = getCampaignNames(data.screens)
              data.algos.push("incomplete") //add unanswered as one of standard reporting options
              console.log(
                "actions - getVideoCollection - data - after campaign"
              )
              console.log(data)
              //GROUP RESULTS BY DATE FOR VIDEOS REPORTING PAGE
              // let groupedResults = lodashGroupBy(results, result =>
              //   moment
              //     .unix(result["datecreated"])
              //     // .format("YYYY-MM-DD")
              //     .startOf("day")
              // )
              let groupedResults = groupBy(
                results,
                (result) => moment.unix(result["datecreated"]).startOf("day")
                // .format("YYYY-MM-DD")
              )

              console.log("--data--")
              console.log(data)

              console.log("--groupedResults--++")
              console.log(groupedResults)

              // let algoObject = {}
              // data.algos.map(name => {
              //   algoObject[name] = groupedResults[key].filter(
              //     item => item.eval === algo
              //   ).length
              // })

              let groupContainer = Object.keys(groupedResults).map(
                (key, index, sourceGroupedResults) => ({
                  time: parseInt(moment(key).format("x")),
                  // value: groupedResults[key].length,
                  algos: getAlgoNames(data, groupedResults, key),
                  // campaigns: getCampaignNames(groupedResults, key),
                })
              )

              console.log(
                "actions - getVideoCollection - PRE SORT - groupContainer"
              )
              console.log(groupContainer)

              // Sort values by time ASC (oldest first)
              groupContainer.sort((a, b) => (a.time > b.time ? 1 : -1))

              console.log(
                "actions - getVideoCollection - POST SORT - groupContainer"
              )
              console.log(groupContainer)

              // let campaignId = "0e7dff0a-c370-423e-89c3-c1f78291b284"

              let sumOfVideoTotalInAlgo = groupContainer.reduce(
                (accum, val) => accum + val.algos.total,
                0
              )

              console.log("sumOfVideoTotalInAlgo")
              console.log(sumOfVideoTotalInAlgo)

              let selectedAlgos = data.algos.reduce(
                (accum, algo, i, sourceArray) => {
                  accum[algo] = true
                  return accum
                },
                {}
              )

              console.log("start - totalByAlgos")

              let totalByAlgos = data.algos.reduce(
                (accum, algo, i, sourceArray) => {
                  console.log("algo")
                  console.log(algo)
                  accum[algo] = results.filter(
                    (item) => item.eval === algo
                  ).length
                  return accum
                },
                {}
              )

              console.log("totalByAlgos")
              console.log(totalByAlgos)

              const uniqueUsers = [
                ...new Set(results.map((item) => item.phone)),
              ].map((phone) => {
                let masterobject = results.find((item) => item.phone === phone)
                return {
                  fname: masterobject.fname,
                  lname: masterobject.lname,
                  phone: masterobject.phone,
                }
              })
              console.log("uniqueUsers")
              console.log(uniqueUsers)

              console.log("actions - getVideoCollection - groupContainer")
              console.log(groupContainer)

              let max = getMaxTime(groupContainer)
              let min = getMinTime(groupContainer)

              // console.log("max")
              // console.log(max)
              // console.log("min")
              // console.log(min)

              //PREPARE SCREENS FOR USER CONTAINER

              let userScreenContainer = Object.keys(groupedResults).map(
                (key, index, sourceGroupedResults) => ({
                  time: parseInt(moment(key).format("x")),
                  data: groupedResults[key],
                  algos: getAlgoNames(data, groupedResults, key),
                })
              )

              console.log(
                "actions - getVideoCollection - PRESORT - userScreenContainer"
              )
              console.log(userScreenContainer)
              // Sort values by time DESC (newest first)
              userScreenContainer.sort((a, b) => (a.time > b.time ? -1 : 1))

              console.log(
                "actions - getVideoCollection - POSTSORT - userScreenContainer"
              )
              console.log(userScreenContainer)

              //SEND MANUALLY PRODUCED TICKS
              let ticks = []
              let incrementValue = 86400000

              for (let i = min; i < parseInt(max + incrementValue); ) {
                ticks.push(i)
                // console.log("i")
                // console.log(i)
                i = i + incrementValue
              }

              // console.log("99ticks")
              // console.log(ticks)

              let screenerMode = ""
              if (data.screens && data.screens[0] && data.screens[0].mode) {
                screenerMode = data.screens[0].mode
              }
              //PREPARE VIDEO REPORTING DATA
              videoResultContainer[templateid] = {
                campaigns: data.campaigns,
                selected: selectedAlgos,
                labels: ticks,
                data: groupContainer,
                total: sumOfVideoTotalInAlgo,
                totalbyalgo: totalByAlgos,
                users: uniqueUsers,
                userfilter: { userfilterid: userfilter ? userfilter : "all" },
                mode: screenerMode,
                bounds: {
                  max: max,
                  min: min,
                },
              }

              if (campaignfilter && campaignfilter !== "all") {
                videoResultContainer[templateid].campaignfilter = {
                  campaignid: campaignfilter,
                }
              }
              // resultContainer[templateid] = groupContainer

              /// WORK ON USERS NOW

              // See: https://stackoverflow.com/questions/2218999/remove-duplicates-from-an-array-of-objects-in-javascript

              // const usersEmailList = results.filter(
              //   (item, index, self) =>
              //     item.email &&
              //     self.findIndex(t => t.email === item.email) === index
              // )
              // const usersPhoneList = results.filter(
              //   (item, index, self) =>
              //     item.phone &&
              //     self.findIndex(t => t.phone === item.phone) === index
              // )
              usersResultContainer[templateid] = {
                // email: usersEmailList,
                screens: userScreenContainer,
              }
              console.log("**usersResultContainer - userScreenContainer")
              console.log(userScreenContainer)
              if (campaignfilter && campaignfilter !== "all") {
                usersResultContainer[templateid].campaignfilter = {
                  campaignid: campaignfilter,
                }
              }
              console.log("usersResultContainer")
              console.log(usersResultContainer)

              console.log("getVideoCollection - results")
              console.log(results)

              // /// WORK ON REVIEWS NOW
              // let reviewsList = results.map((item, i) => ({
              //   id: item.id,
              //   sessionid: item.sessionid,
              //   email: item.email,
              //   phone: item.phone,
              //   emailconfirm: item.emailconfirm,
              //   smsconfirm: item.smsconfirm,
              //   datecreated: item.datecreated,
              //   tags: item.tags,
              // }))

              // console.log("getVideoCollection - reviewsList")
              // console.log(reviewsList)

              // reviewsList = reviewsList.sort((a, b) =>
              //   a.datecreated > b.datecreated ? -1 : 1
              // )

              // reviewsResultContainer[templateid] = reviewsList

              // console.log("reviewsResultContainer")
              // console.log(reviewsResultContainer)

              console.log("actions - getVideoCollection - resolve")

              return resolve(
                dispatch({
                  type: "VIDEOS_CREATED_GROUPED_REPLACE",
                  data: videoResultContainer,
                }),
                dispatch({
                  type: "VIDEOS_USERS_REPLACE",
                  data: usersResultContainer,
                })
                // dispatch({
                //   type: "REVIEWS_REPLACE",
                //   data: reviewsResultContainer,
                // })
              )
            })
            .catch((error) => {
              console.log("actions - getVideoCollection - catch")
              console.log(error)
              return resolve(
                dispatch({
                  type: "LOADING_END",
                })
              )
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}

export function getCampaignFilters(mode, templateidArray, templateid) {
  let cid = Cache.getItem("cid")

  console.log("actions - createScreener - cid")
  console.log(cid)

  let apiName = "Picohealthweb"
  let path = "admin/template/metadata"

  return (dispatch) => {
    // dispatch({
    //   type: "LOADING_START",
    // })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - getCampaigns - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        // let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            cid: cid,
            id: templateidArray,
            mode: mode,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - getCampaignFilters - API.get - data")
              console.log(data)

              return resolve(
                dispatch({
                  type: "CAMPAIGNFILTER_METADATA_REPLACE",
                  data: { templateid: templateid, metadata: data },
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
