import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

export function getCampaigns() {
  let cid = Cache.getItem("cid")

  console.log("actions - getCampaigns - cid")
  console.log(cid)

  let apiName = "Picohealthweb"
  let path = "admin/template/list"

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - getCampaigns - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid: cid,
          },
        }

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              console.log("actions - getCampaigns - API.get - data")
              console.log(data)

              let result = data.map((item, i) => ({
                id: item.id,
                title: item.title,
                datecreated: item.datecreated,
                criteria: JSON.parse(item.algorithm),
                landing: JSON.parse(item.landing),
                prompts: JSON.parse(item.prompts),
                deliver: JSON.parse(item.deliver),
                survey: JSON.parse(item.survey),
                keyword: item.keyword,
                pushcampaign: item.pushcampaign
                  ? JSON.parse(item.pushcampaign)
                  : [],
              }))
              console.log("actions - getCampaigns - API.get - result")
              console.log(result)

              //Sort with latest template on top
              result.sort((a, b) => (a.datecreated > b.datecreated ? 1 : -1))

              return resolve(
                dispatch({
                  type: "CAMPAIGNS_REPLACE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
