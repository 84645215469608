import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"

export function updateSendGroupFieldDirect(group, prop, val) {
  console.log(
    "actions - createSendGroup - updateSendGroupFieldDirect - pre dispatch"
  )
  return (dispatch) => {
    dispatch({
      type: "SENDGROUP_UPDATE_DIRECT_OBJ",
      data: { group, prop, val },
    })
  }
}

export function updateSendGroupOption(mode, formoption, marker, val, prop) {
  return (dispatch) => {
    dispatch({
      type: "SENDGROUP_UPDATE_FORM_OPTION",
      data: { formoption, marker, val, prop },
      mode: mode,
    })
  }
}
export function updateSendGroupDay(marker, val, prop) {
  return (dispatch) => {
    dispatch({
      type: "SENDGROUP_UPDATE_FORM_DAY",
      data: { marker, val, prop },
    })
  }
}

export function createSendGroup(deliver, sendgroup, users, templates, id) {
  console.log("actions - createSendGroup - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createSendGroup - cid")
  console.log(cid)

  let apiName = "Picohealthweb"

  let path = "admin/sendgroup/create"

  return (dispatch) => {
    dispatch({
      type: "SENDGROUP_CREATE_UPDATE_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createSendGroup - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            deliver,
            sendgroup,
            users,
            templates,
            cid,
            id,
          },
        }

        console.log(
          "actions - createSendGroup - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createSendGroup - API.post - result")
              console.log(result)

              navigate("/app/sendgroup/")

              return resolve(
                dispatch({
                  type: "SENDGROUP_CREATE_UPDATE_COMPLETE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
      }) //end get Auth token
  }
}
