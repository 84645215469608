//React
import React from "react"

//Gatsby
import { Link } from "gatsby"

class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div class="feature-header-menu mb-2">
        &laquo;
        <Link to={this.props.link}>
          <span>{this.props.text}</span>
        </Link>
      </div>
    )
  }
}
export default Snippet
