//React
import React from "react"
import Layout from "../../components/layout"

//Components
import PasswordReset from "./passwordResetComponent"

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout>
        <div class="container d-flex flex-grow-1">
          <div class="row d-flex flex-grow-1 flex-row justify-content-center align-items-start mt-3 mt-lg-4">
            <div class="col-md-6 p-5 d-flex align-self-stretch">
              <PasswordReset />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
