const surveySample1 = [
  {
    choices: [
      { text: { default: "Yes" }, value: "Yes" },
      { text: { default: "No" }, value: "No" },
    ],
    isRequired: true,
    name: "question1",
    title: {
      default: "Have you had a fever of 100.3 F or greater in the last 3 days?",
    },
    type: "radiogroup",
  },
  {
    choices: [
      { text: { default: "Yes" }, value: "Yes" },
      { text: { default: "No" }, value: "No" },
    ],
    isRequired: true,
    name: "question2",
    title: {
      default: "Have you had a cough in the last 3 days?",
    },
    type: "radiogroup",
  },
  {
    choices: [
      { text: { default: "Yes" }, value: "Yes" },
      { text: { default: "No" }, value: "No" },
    ],
    isRequired: true,
    name: "question3",
    title: {
      default:
        "Have you had any of these symptoms in the last 3 days: shortness of breath, fatigue, muscle/body aches, headache, change in taste or smell, sore throat, congestion, runny nose, nausea/vomiting, or diarrhea?",
    },
    type: "radiogroup",
  },
  {
    choices: [
      {
        text: { default: "Yes, with a positive result" },
        value: "Yes, with a positive result",
      },
      {
        text: { default: "Yes, with a negative result" },
        value: "Yes, with a negative result",
      },
      {
        text: { default: "Yes, and waiting for the result" },
        value: "Yes, and waiting for the result",
      },
      { text: { default: "No" }, value: "No" },
    ],
    isRequired: true,
    name: "question4",
    title: {
      default: "Have you been tested for COVID-19 in the last week?",
    },
    type: "radiogroup",
  },
  {
    choices: [
      { text: { default: "Yes" }, value: "Yes" },
      { text: { default: "No" }, value: "No" },
    ],
    isRequired: true,
    name: "question5",
    title: {
      default:
        "Have you been in contact with someone known or suspected to have COVID-19 in the last week?",
    },
    type: "radiogroup",
  },
]

const criteriaSample1 = [
  {
    deliver: [
      {
        lang: "en",
        text: "Based on your responses, please come in for your shift.",
        title: "Thank you",
        displayimage: "success",
      },
    ],
    isRequired: true,
    name: "default",
    pos: 1,
    title: "default",
    type: "condition",
  },
  {
    deliver: [
      {
        lang: "en",
        text:
          "It looks like we might need to reschedule your shift. Please call your supervisor for more details.",
        title: "Reschedule",
        displayimage: "warning",
      },
    ],
    isRequired: true,
    name: "condition2",
    operator: "or",
    pos: 2,
    title: "default",
    type: "condition",
    test: {
      data: [
        { dataname: "question1", dataval: "Yes" },
        { dataname: "question2", dataval: "Yes" },
        { dataname: "question3", dataval: "Yes" },
        { dataname: "question4", dataval: "Yes, with a positive result" },
        { dataname: "question4", dataval: "Yes, and waiting for the result" },
        { dataname: "question5", dataval: "Yes" },
      ],
      value: "",
    },
    title: "Reschedule",
    type: "condition",
  },
]

//these need to be one more than the number of corresponding questions or criteria above
const surveySample1Counter = 6
const criteriaSample1Counter = 3

module.exports = {
  surveySample1,
  criteriaSample1,
  surveySample1Counter,
  criteriaSample1Counter,
}
