//React
import React from "react"
import { connect } from "react-redux"

//Amplify
import Amplify from "@aws-amplify/core"
import { amplifyconfig } from "../utils/config"

//Packages
import parse from "url-parse"

//Actions
import { getCampaigns } from "../actions/getCampaigns"
import { getSendGroups } from "../actions/getSendGroups"

//Components
import Layout from "../components/layout"
import ReportingMenu from "../components/appReporting/reportingMenu"
import ReportingVideoCreation from "../components/appReporting/reportingVideoCreation"
import AppHome from "../components/appHome/consoleHome"
import AppScreenerCreate from "../components/appScreenerCreate/appScreenerCreate"
import AppScreenerCreatePosition from "../components/appScreenerCreate/appScreenerCreatePosition"
import AppSendGroupCreate from "../components/appSendGroupCreate/appSendGroupCreate"
import NavApp from "../components/nav/navApp"
import MenuBack from "../components/appReporting/menuBack"
import ReportTitle from "../components/appReporting/reportTitle"

//Redux
const mapStateToProps = ({ campaign, sendgroup, isLoading }) => {
  return { campaign, sendgroup, isLoading }
}

const mapDispatchToProps = (dispatch) => ({
  getCampaigns: () => dispatch(getCampaigns()),
  getSendGroups: () => dispatch(getSendGroups()),
})

//Configure
Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    console.log(">>>>>>this.props.campaign<<<<<<<")
    console.log(this.props.campaign)
    console.log(">>>>>>this.props.sendgroup<<<<<<<")
    console.log(this.props.sendgroup)
    if (!this.props.campaign) {
      this.props.getCampaigns()
    }
    if (!this.props.sendgroup) {
      this.props.getSendGroups()
    }
  }

  render() {
    // console.log("((this.props))")
    // console.log(this.props)

    const {
      campaign,
      sendgroup,
      sendgroupcomplete,
      isLoading,
      isLoadingTemplateCreate,
    } = this.props
    const url = parse(window.location.href, true)

    console.log("sendgroup")
    console.log(sendgroup)

    const pathname = url.pathname
    // console.log("((pathname))")
    // console.log(pathname)
    const templateid = url.query.t
    const campaignDetail = campaign
      ? campaign.find(({ id }) => id === url.query.t)
      : {}

    const campaignDetailTitle =
      campaignDetail && campaignDetail.title ? campaignDetail.title : ""
    const campaignDetailPoster =
      campaignDetail && campaignDetail.poster ? campaignDetail.poster : ""
    console.log("campaign - campaign")
    console.log(campaign)
    console.log("campaignDetail - campaign")
    console.log(campaignDetail)

    const menuData = [
      {
        header: "Reports",
        items: [
          {
            title: "Trends",
            url: "/app/trends/",
            passtemplate: true,
          },
          {
            title: "Detail",
            url: "/app/detail/",
            passtemplate: true,
          },
        ],
      },
    ]
    console.log("[[[[campaignDetail]]]]")
    console.log(campaignDetail)
    return (
      <Layout>
        <div class="flex-grow-1">
          <NavApp title="Console" link={true} />

          <div class="container container-app container-page container-reporting">
            {pathname === "/app/trends/" || pathname === "/app/detail/" ? (
              <div class="row pl-3">
                <MenuBack text="Back" link="/app/screener/" />
              </div>
            ) : null}

            <div class="row">
              {pathname === "/app/screener/create/" ||
              pathname === "/app/sendgroup/create/" ? (
                <div class="col-sm-2">
                  {pathname === "/app/screener/create/" ? (
                    <MenuBack text="Back" link="/app/screener/" />
                  ) : null}
                  {pathname === "/app/sendgroup/create/" ? (
                    <MenuBack text="Back" link="/app/sendgroup/" />
                  ) : null}
                </div>
              ) : (
                <div class="col-sm-2">
                  <ReportingMenu
                    templateid={templateid}
                    menudata={menuData}
                    campaignDetail={campaignDetail}
                  />
                </div>
              )}
              <>
                {/* {isLoading ? "true" : "false"} */}
                {pathname === "/app/trends/" || pathname === "/app/detail/" ? (
                  <div class="col-sm-10">
                    <ReportTitle
                      text={campaignDetailTitle}
                      bgcolor="dkblue-1"
                      templateid={templateid}
                    />
                    <ReportingVideoCreation />
                  </div>
                ) : null}
                {pathname === "/app/screener/create/" ? (
                  <div class="col-sm-10">
                    <ReportTitle text="Create screener" bgcolor="dkblue-1" />
                    {!isLoadingTemplateCreate ? (
                      <AppScreenerCreatePosition />
                    ) : null}
                    <AppScreenerCreate />
                  </div>
                ) : null}
                {pathname === "/app/sendgroup/create/" ? (
                  <div class="col-sm-10">
                    <ReportTitle
                      text={`${templateid ? "Edit" : "Create"} send group`}
                      bgcolor="grayblue-1"
                    />
                    <AppSendGroupCreate />
                  </div>
                ) : null}
                {pathname === "/app/screener/" ? (
                  <div class="col-sm-12">
                    <AppHome />
                  </div>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// export default App
export default connect(mapStateToProps, mapDispatchToProps)(App)
