//React
import React from "react"
import { connect } from "react-redux"

//Actions
import {
  checkKeyword,
  updateScreenerForm,
  removeScreenerItem,
  updateScreenerOption,
  updateScreenerField,
} from "../../actions/createScreener"

//Packages
import { v4 as uuidv4 } from "uuid"

//Components
import ModalLoadSample from "./modalLoadSample"

//Icons
import { FaTimes, FaPlus, FaClipboardList } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  templatekeyword,
  templatecreate,
  isLoadingTemplateCheck,
}) => {
  return { templatekeyword, templatecreate, isLoadingTemplateCheck }
}

const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val, mode) =>
    dispatch(updateScreenerForm(prop, val, mode)),
  checkKeyword: (keyword) => dispatch(checkKeyword(keyword)),
  removeScreenerItem: (prop, pos) => dispatch(removeScreenerItem(prop, pos)),
  updateScreenerOption: (mode, formoption, marker, pos, val, lang) =>
    dispatch(updateScreenerOption(mode, formoption, marker, pos, val, lang)),
  updateScreenerField: (group, marker, prop, val, lang) =>
    dispatch(updateScreenerField(group, marker, prop, val, lang)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const { templatecreate } = this.props

    if (
      templatecreate &&
      templatecreate.survey &&
      templatecreate.survey.length &&
      templatecreate.survey.length > 0
    ) {
      //Create an array with just the question numbers
      const questionNameArray = templatecreate.survey.map((question) =>
        parseInt(question.name.replace("question", ""))
      )
      console.log("questionNameArray")
      console.log(questionNameArray)

      //Find the maximum  value in the question set, and increment it
      const maxPos = Math.max.apply(
        Math,
        questionNameArray.map((o) => o)
      )
      console.log("maxPos")
      console.log(maxPos)
      this.props.updateScreenerForm("surveycounter", maxPos + 1)
    }
  }

  handleUpdateColor = (event) => {
    console.log("handleUpdateColor - event")
    console.log(event.hex)
    this.props.updateScreenerForm("colorPicker", event.hex)
  }
  updateField = (event, group, marker, prop, lang) => {
    this.props.updateScreenerField(
      group,
      marker,
      prop,
      event.target.value,
      lang
    )
  }
  updateOption = (val, mode, formoption, marker, pos, lang) => {
    this.props.updateScreenerOption(mode, formoption, marker, pos, val, lang)
  }

  removeSurveyItem = (prop, pos) => {
    this.props.removeScreenerItem(prop, pos)
  }
  handleUpdate = (event, mode, pos) => {
    console.log("handleUpdate - event")
    console.log(event)
    if (event.target.name === "newQuestion") {
      let inputJSON = {}
      switch (mode) {
        case "input":
          this.props.updateScreenerForm(
            event.target.name[pos],
            event.target.value
          )
          break
        case "radiogroup":
          inputJSON = {
            type: "radiogroup",
            name: `question${pos}`,
            title: { default: "" },
            isRequired: true,
            choices: [
              { value: "", text: { default: "" } },
              { value: "", text: { default: "" } },
            ],
            // choices: [{ lang: "en", data: ["", ""] }],
          }
          console.log("inputJSON")
          console.log(inputJSON)
          this.props.updateScreenerForm(event.target.name, inputJSON, "survey")
          break
        case "text":
          inputJSON = {
            type: "text",
            name: `question${pos}`,
            title: { default: "" },
            placeHolder: "Enter response",
            isRequired: true,
          }
          console.log("inputJSON")
          console.log(inputJSON)
          this.props.updateScreenerForm(event.target.name, inputJSON, "survey")
          break
        case "select":
          // code block
          break
        default:
        // code block
      }

      return
    } else {
      this.props.updateScreenerForm(event.target.name, event.target.value)
    }
  }

  checkKeywordAvailability(event, keyword) {
    this.props.checkKeyword(keyword)
  }

  buildOptionItem(templatecreate, i, j) {
    let criteriaDisable = templatecreate.criteria.length > 1 ? true : false

    return (
      <div class="d-flex flex-row" key={`radio-option-${i}-${j}`}>
        <div class="form-check d-flex flex-column">
          <input
            class="form-check-input"
            type="radio"
            name="gridRadios"
            id={`radio-${i}-${j}`}
            // value={templatecreate.survey[i].choices[j]}
          />
          <label
            class="form-check-label font-weight-light"
            for={`radio-${i}-${j}`}
          >
            {/* {choice} */}
            <input
              class={`form-check-input-option ${
                templatecreate.error.surveyitems &&
                templatecreate.error.surveyitems[i] &&
                templatecreate.error.surveyitems[i].choices &&
                templatecreate.error.surveyitems[i].choices[j] &&
                templatecreate.error.surveyitems[i].choices[j].title
                  ? "is-invalid"
                  : ""
              }`}
              disabled={criteriaDisable}
              type="text"
              name="gridRadiosOption"
              placeholder="Enter an option"
              id={`radio-option-label-${i}-${j}`}
              value={templatecreate.survey[i].choices[j].text["default"]}
              onChange={(event) =>
                this.updateOption(
                  event.target.value,
                  "update",
                  "choices",
                  i,
                  j,
                  "en"
                )
              }
              required
            />
          </label>
        </div>
        {criteriaDisable ? (
          <button type="button" class="btn btn-sm">
            <FaTimes class="gray-1" size={11} />
            <small class="gray-1 pl-1"></small>
          </button>
        ) : (
          <button
            type="button"
            class="btn btn-sm"
            onClick={(event) =>
              this.updateOption(
                event.target.value,
                "delete",
                "choices",
                i,
                j,
                "en"
              )
            }
          >
            <FaTimes class="dkorange-1" size={11} />
            <small class="dkorange-1 pl-1"></small>
          </button>
        )}
      </div>
    )
  }
  buildTextItem(templatecreate, i) {
    let criteriaDisable = templatecreate.criteria.length > 1 ? true : false

    return (
      <div class="d-flex flex-row" key={`text-option-${i}`}>
        <div class="form-check d-flex flex-column">
          <input
            class={`form-check-input-option gray-4`}
            disabled={true}
            type="text"
            name="textOption"
            placeholder=""
            id={`text-option-label-${i}`}
            value="[User response]"
          />
        </div>
      </div>
    )
  }

  render() {
    const { templatecreate } = this.props
    const { templateid } = this.state

    console.log("templatecreate")
    console.log(templatecreate)

    let surveyLengthError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.surveylength
        ? true
        : false

    let surveyContainsError =
      templatecreate && templatecreate.error && templatecreate.error.surveyerror
        ? true
        : false

    let criteriaDisable = templatecreate.criteria.length > 1 ? true : false

    const renderSurvey = templatecreate.survey
      ? templatecreate.survey.map((item, i) => {
          if (item.type === "radiogroup") {
            return (
              <div
                class="form-question-group d-flex flex-row justify-content-start align-items-start"
                key={`radio-item-${i}`}
              >
                <button type="button" class="btn btn-sm">
                  {criteriaDisable ? (
                    <FaTimes
                      class="gray-1"
                      size={16}
                      style={{ marginTop: -6 }}
                    />
                  ) : (
                    <FaTimes
                      class="red-1"
                      size={16}
                      style={{ marginTop: -6 }}
                      onClick={() => this.removeSurveyItem("survey", i)}
                    />
                  )}
                </button>
                <div class="w-100">
                  <textarea
                    type="text"
                    class={`form-item-title ${
                      templatecreate.error.surveyitems &&
                      templatecreate.error.surveyitems[i] &&
                      templatecreate.error.surveyitems[i].title
                        ? "is-invalid"
                        : ""
                    }`}
                    rows={1}
                    disabled={criteriaDisable}
                    placeholder="Enter the question title"
                    id={`radio-title-${i}`}
                    onChange={(e) =>
                      this.updateField(e, "survey", i, "title", "default")
                    }
                    value={templatecreate.survey[i].title["default"]}
                  />
                  {item && item.choices
                    ? item.choices.map((choice, j) =>
                        this.buildOptionItem(templatecreate, i, j)
                      )
                    : null}

                  {criteriaDisable ? (
                    <button type="button" class="btn btn-sm">
                      <FaPlus class="gray-1" size={12} />
                      <small class="gray-1 pl-1">Add option</small>
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-sm"
                      onClick={(event) =>
                        this.updateOption(
                          event.target.value,
                          "add",
                          "choices",
                          i,
                          item.choices.length,
                          "en"
                        )
                      }
                    >
                      <FaPlus class="dkgreen-1" size={12} />
                      <small class="dkgreen-1 pl-1">Add option</small>
                    </button>
                  )}
                </div>
              </div>
            )
          } else if (item.type === "text") {
            return (
              <div
                class="form-question-group d-flex flex-row justify-content-start align-items-start"
                key={`text-item-${i}`}
              >
                <button type="button" class="btn btn-sm">
                  {criteriaDisable ? (
                    <FaTimes
                      class="gray-1"
                      size={16}
                      style={{ marginTop: -6 }}
                    />
                  ) : (
                    <FaTimes
                      class="red-1"
                      size={16}
                      style={{ marginTop: -6 }}
                      onClick={() => this.removeSurveyItem("survey", i)}
                    />
                  )}
                </button>
                <div class="w-100">
                  <textarea
                    type="text"
                    class={`form-item-title ${
                      templatecreate.error.surveyitems &&
                      templatecreate.error.surveyitems[i] &&
                      templatecreate.error.surveyitems[i].title
                        ? "is-invalid"
                        : ""
                    }`}
                    rows={1}
                    disabled={criteriaDisable}
                    placeholder="Enter the question title"
                    id={`text-title-${i}`}
                    onChange={(e) =>
                      this.updateField(e, "survey", i, "title", "default")
                    }
                    value={templatecreate.survey[i].title["default"]}
                  />

                  {this.buildTextItem(templatecreate, i)}
                </div>
              </div>
            )
          }
        })
      : null
    console.log("[[[templatecreate]]]")
    console.log(templatecreate)

    return (
      <>
        <div class="form-group">
          <label for="newQuestion">Screener questions</label>
          {criteriaDisable ? (
            <div class="dkblue-1 pb-3">
              <small>
                To edit your screener questions, please remove all custom
                conditions from Step 4 first.
              </small>
            </div>
          ) : null}

          <div class="d-flex flex-row">
            <div class={`dropdown`}>
              {criteriaDisable ? (
                <button
                  class={`btn btn-gray btn-cta btn-hover-disable`}
                  type="button"
                  name="newQuestion"
                  id="newQuestion"
                >
                  Add question +
                </button>
              ) : (
                <>
                  <button
                    class={`btn btn-dkgreen btn-cta`}
                    type="button"
                    name="newQuestion"
                    id="newQuestion"
                    onClick={(e) => {
                      this.handleUpdate(
                        e,
                        "radiogroup",
                        templatecreate.surveycounter
                      )
                    }}
                  >
                    Multiple choice question +
                    {/* <FaPlus class="dkgreen-1 btn-arrow" size={14} /> */}
                  </button>
                  <button
                    class={`btn btn-dkgreen btn-cta ml-md-1 mt-1 mt-md-0`}
                    type="button"
                    name="newQuestion"
                    id="newQuestion"
                    onClick={(e) => {
                      this.handleUpdate(e, "text", templatecreate.surveycounter)
                    }}
                  >
                    Text question +
                    {/* <FaPlus class="dkgreen-1 btn-arrow" size={14} /> */}
                  </button>
                </>
              )}

              {/* <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              name="newQuestion1"
              id="newQuestion1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Add question
            </button> */}
              {/* <div class="dropdown-menu" aria-labelledby="newQuestion">
              <a
                class="dropdown-item"
                href="#"
                onClick={() => {
                  this.handleUpdate()
                }}
              >
                Freeform text input
              </a>
              <a
                class="dropdown-item"
                href="#"
                name="newQuestion"
                id="newQuestion"
                onClick={e => {
                  this.handleUpdate(
                    e,
                    "radiogroup",
                    templatecreate.surveycounter
                  )
                }}
              >
                Multiple choice (radio)
              </a>
              <a class="dropdown-item" href="#">
                Multiple choice (checkbox)
              </a>
            </div> */}
            </div>

            <button
              class={`btn btn-dkblue btn-cta ml-2`}
              type="button"
              name="loadSample"
              id="loadSample"
              data-toggle="modal"
              data-target="#loadSampleModal"
            >
              Load template{" "}
              <FaClipboardList
                class="dkblue-1"
                size={16}
                style={{ marginTop: -4 }}
              />
            </button>
          </div>
        </div>
        {surveyLengthError ? (
          <div class="invalid-feedback">
            {templatecreate.error.surveylength}
          </div>
        ) : null}
        {surveyContainsError ? (
          <div class="invalid-feedback">{templatecreate.error.surveyerror}</div>
        ) : null}
        <div class="form-group">{renderSurvey}</div>
        <ModalLoadSample />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
