//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import parse from "url-parse"

//Actions
import {
  checkKeyword,
  updateScreenerForm,
  updateScreenerOption,
  updatePrompts,
  updateScreenerFieldDirect,
} from "../../actions/createScreener"
import { getSendGroups } from "../../actions/getSendGroups"

//Components
import Loading from "../loading"

//Icons
import { MdRefresh } from "react-icons/md"
import { FaCheck, FaTimesCircle, FaPlus, FaTimes } from "react-icons/fa"

//Redux
const mapStateToProps = ({
  templatekeyword,
  templatecreate,
  sendgroup,
  isLoadingTemplateCheck,
  isLoadingSendGroup,
}) => {
  return {
    templatekeyword,
    templatecreate,
    sendgroup,
    isLoadingTemplateCheck,
    isLoadingSendGroup,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val, mode) =>
    dispatch(updateScreenerForm(prop, val, mode)),
  updateScreenerFieldDirect: (group, prop, val) =>
    dispatch(updateScreenerFieldDirect(group, prop, val)),
  updateScreenerOption: (mode, formoption, marker, pos, val, lang) =>
    dispatch(updateScreenerOption(mode, formoption, marker, pos, val, lang)),
  updatePrompts: (prop, val, marker, lang) =>
    dispatch(updatePrompts(prop, val, marker, lang)),
  checkKeyword: (keyword) => dispatch(checkKeyword(keyword)),
  getSendGroups: () => dispatch(getSendGroups()),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.getSendGroups()
    console.log("createStage 1 - url")
    console.log(url)
    if (!url.query.t) {
      this.updatePrompts(
        "text",
        "Please fill out our health questionnaire below",
        0,
        "en"
      )
    }
  }
  getSendGroups() {
    this.props.getSendGroups()
  }
  updatePrompts = (prop, val, marker, lang) => {
    this.props.updatePrompts(prop, val, marker, lang)
  }
  updateScreenerFieldDirect = (group, prop, val) => {
    this.props.updateScreenerFieldDirect(group, prop, val)
  }

  updateOption = (val, mode, formoption, marker, pos, lang) => {
    this.props.updateScreenerOption(mode, formoption, marker, pos, val, lang)
  }

  handleUpdate = (event) => {
    console.log("handleUpdate - event")
    console.log(event)
    if (event.target.name === "logoUpload") {
      this.props.updateScreenerForm(
        event.target.name,
        URL.createObjectURL(event.target.files[0])
      )
    } else {
      this.props.updateScreenerForm(event.target.name, event.target.value)
    }
  }

  checkKeywordAvailability(keyword) {
    this.props.checkKeyword(keyword)
  }

  render() {
    const {
      templatekeyword,
      templatecreate,
      sendgroup,
      isLoadingTemplateCheck,
      isLoadingSendGroup,
    } = this.props
    const { templateid } = this.state

    console.log("templatecreate")
    console.log(templatecreate)
    console.log("templatekeyword")
    console.log(templatekeyword)
    console.log("sendgroup")
    console.log(sendgroup)

    let screenerNameError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.screenername
        ? true
        : false
    let screenerKeywordError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.screenerkeyword
        ? true
        : false
    let screenerPushCampaignLengthError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.screenerpushcampaignlength
        ? true
        : false

    let screenerPushCampaignItemsContainsError =
      templatecreate &&
      templatecreate.error &&
      templatecreate.error.screenerpushcampaignitemserror
        ? true
        : false

    let screenerPromptText =
      templatecreate && templatecreate.error && templatecreate.error.prompttext
        ? true
        : false

    return (
      <>
        <div class="form-group">
          <label for="screenerName">Screener name</label>
          <input
            type="text"
            class={`form-control ${screenerNameError ? "is-invalid" : ""}`}
            name="screenerName"
            id="screenerName"
            aria-describedby="screenerNameHelp"
            placeholder="Enter screener name"
            onChange={(e) => {
              this.updateScreenerFieldDirect("screener", "name", e.target.value)
            }}
            value={
              templatecreate.screener && templatecreate.screener.name
                ? templatecreate.screener.name
                : ""
            }
            required
          />
          <small id="screenerNameHelp" class="form-text text-muted">
            This name will be used to identify your screener to your users and
            in the admin dashboard
          </small>
          {screenerNameError ? (
            <div class="invalid-feedback">
              {templatecreate.error.screenername}
            </div>
          ) : null}
        </div>
        <div class="form-group">
          <div class="font-weight-bold">Screener mode</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="screenermode"
              id="screenerMode1"
              value="response"
              checked={
                templatecreate.screener &&
                templatecreate.screener.mode &&
                templatecreate.screener.mode === "response"
                  ? true
                  : false
              }
              onChange={(e) => {
                this.updateScreenerFieldDirect(
                  "screener",
                  "mode",
                  e.target.value
                )
              }}
            />
            <label
              class="form-check-label font-weight-medium"
              for="screenerMode1"
            >
              Response
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="screenermode"
              id="screenerMode2"
              value="push"
              checked={
                templatecreate.screener &&
                templatecreate.screener.mode &&
                templatecreate.screener.mode === "push"
                  ? true
                  : false
              }
              onChange={(e) => {
                this.updateScreenerFieldDirect(
                  "screener",
                  "mode",
                  e.target.value
                )
              }}
            />
            <label
              class="form-check-label font-weight-medium"
              for="screenerMode2"
            >
              Push
            </label>
          </div>
        </div>

        {templatecreate &&
        templatecreate.screener &&
        templatecreate.screener.mode &&
        templatecreate.screener.mode === "push" ? (
          <div class="form-group">
            <label for="pushCampaign">Push Send Group</label>
            {sendgroup && sendgroup.length && sendgroup.length > 0 ? (
              <>
                {templatecreate.screener.pushcampaign
                  ? templatecreate.screener.pushcampaign.map(
                      (sendgroupitem, i) => (
                        <div
                          class="input-group mb-3"
                          key={`sendgroup-input-group-${i}`}
                        >
                          <select
                            class={`form-control ${
                              templatecreate.error.screenerpushcampaignitems &&
                              templatecreate.error.screenerpushcampaignitems[i]
                                ? "is-invalid"
                                : ""
                            }`}
                            name="pushcampaign"
                            id="pushcampaign"
                            value={
                              templatecreate.screener &&
                              templatecreate.screener.pushcampaign &&
                              templatecreate.screener.pushcampaign[i]
                                ? templatecreate.screener.pushcampaign[i]
                                : ""
                            }
                            onChange={(event) =>
                              this.updateOption(
                                event.target.value,
                                "update",
                                "pushcampaign",
                                i,
                                null
                              )
                            }
                          >
                            <option value="">Select...</option>
                            {sendgroup
                              ? sendgroup.map((sendgroupitem, j) => (
                                  <option
                                    value={sendgroupitem.id}
                                    key={`option-sendgroup-${j}`}
                                  >
                                    {sendgroupitem.title}
                                  </option>
                                ))
                              : null}
                          </select>
                          <div class="input-group-append">
                            <button
                              type="button"
                              class="btn-append"
                              onClick={() => {
                                this.getSendGroups()
                              }}
                            >
                              <span class="input-group-text" id="basic-addon2">
                                <MdRefresh
                                  size={18}
                                  class={isLoadingSendGroup ? "icon-spin" : ""}
                                />
                              </span>
                            </button>
                          </div>
                          <button
                            type="button"
                            class="btn btn-sm"
                            onClick={(event) =>
                              this.updateOption(
                                templatecreate.screener.pushcampaign[i],
                                "delete",
                                "pushcampaign",
                                i,
                                null
                              )
                            }
                          >
                            <FaTimes class="dkorange-1" size={11} />
                          </button>
                        </div>
                      )
                    )
                  : null}

                <div class="d-flex flex-column align-items-start justify-content-center">
                  <button
                    type="button"
                    class="btn btn-sm"
                    onClick={(event) =>
                      this.updateOption(
                        event.target.value,
                        "add",
                        "pushcampaign",
                        0,
                        ""
                      )
                    }
                  >
                    <FaPlus class="dkgreen-1" size={12} />
                    <small class="dkgreen-1 pl-1">Add push group</small>
                  </button>
                </div>
              </>
            ) : (
              <div>No send groups available. </div>
            )}
            {screenerPushCampaignLengthError ? (
              <div class="invalid-feedback">
                {templatecreate.error.screenerpushcampaignlength}
              </div>
            ) : null}
            {screenerPushCampaignItemsContainsError ? (
              <div class="invalid-feedback">
                {templatecreate.error.screenerpushcampaignitemserror}
              </div>
            ) : null}
            <a href="/app/sendgroup/create/" class="dkblue-1" target="_blank">
              Create a new push group
            </a>
          </div>
        ) : null}
        {templatecreate &&
        templatecreate.screener &&
        templatecreate.screener.mode &&
        templatecreate.screener.mode === "response" ? (
          <div class="form-group">
            <label for="exampleInputPassword1">Keyword</label>
            <input
              type="text"
              class={`form-control ${screenerKeywordError ? "is-invalid" : ""}`}
              name="keyword"
              id="keyword"
              aria-describedby="keywordHelp"
              placeholder="Enter keyword"
              onChange={(e) => {
                this.updateScreenerFieldDirect(
                  "screener",
                  "keyword",
                  e.target.value.toLowerCase()
                )
              }}
              // onBlur={e =>
              //   this.checkKeywordAvailability(templatecreate.screener.keyword)
              // }
              value={templatecreate.screener.keyword || ""}
            />
            <small id="keywordHelp" class="form-text text-muted">
              This keyword is what your users will send in via SMS to start the
              screener
            </small>
            {/* {screenerKeywordError ? (
              <div class="invalid-feedback">
                {templatecreate.error.screenerkeyword}
              </div>
            ) : null} */}

            <div class="d-flex flex-row p-2">
              {isLoadingTemplateCheck ? <Loading class="p-2 small" /> : null}
              {(templatekeyword === true &&
                templatekeyword !== "initial" &&
                !isLoadingTemplateCheck) ||
              (templatekeyword === false && !isLoadingTemplateCheck) ? (
                templatekeyword ? (
                  <div class="d-flex flex-row align-items-center justify-content-start">
                    <FaCheck class="dkgreen-1 mr-1" />{" "}
                    <small class="dkgreen-1">Keyword is available</small>
                  </div>
                ) : (
                  <div class="d-flex flex-row align-items-center justify-content-start">
                    <FaTimesCircle class="red-1 mr-1" />
                    <small class="red-1">
                      Keyword is already taken, please try a different one
                    </small>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : null}

        <div class="form-group">
          <label for="promptText">Welcome SMS</label>
          <input
            type="text"
            class={`form-control ${screenerPromptText ? "is-invalid" : ""}`}
            name="promptText"
            id="promptText"
            aria-describedby="promptTextHelp"
            placeholder="Enter text for welcome SMS"
            onChange={(e) => {
              this.updatePrompts("text", e.target.value, 0, "en")
            }}
            value={
              templatecreate.prompts &&
              templatecreate.prompts.find((item) => item.lang === "en") &&
              templatecreate.prompts.find((item) => item.lang === "en").data &&
              templatecreate.prompts.find((item) => item.lang === "en")
                .data[0] &&
              templatecreate.prompts.find((item) => item.lang === "en").data[0]
                .text
                ? templatecreate.prompts.find((item) => item.lang === "en")
                    .data[0].text
                : ""
            }
          />
          <small id="promptTextHelp" class="form-text text-muted">
            This text will precede the screener link in the SMS sent to your
            users
          </small>
          {screenerPromptText ? (
            <div class="invalid-feedback">
              {templatecreate.error.prompttext}
            </div>
          ) : null}
        </div>
      </>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
// export default connect(mapStateToProps, null)(Snippet)
