//React
import React from "react"
import { connect } from "react-redux"

//Actions
import { updateSendGroupFieldDirect } from "../../actions/createSendGroup"

//Components
import Loading from "../loading"

//Icons
import { FaPlus } from "react-icons/fa"

//Exceptions
const moment = require("moment-timezone")

//Redux
const mapStateToProps = ({ sendgroupcreate }) => {
  return { sendgroupcreate }
}

const mapDispatchToProps = (dispatch) => ({
  updateSendGroupFieldDirect: (prop, val, mode) =>
    dispatch(updateSendGroupFieldDirect(prop, val, mode)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}

  translateTimeMeridian(time, meridian, toMilitary) {
    let hourSplitMilitary = moment(`${time} ${meridian}`, "H a").format("H")
    let hourSplit = moment(`${time} ${meridian}`, "H a").format("h")
    let meridianSplit = moment(`${time} ${meridian}`, "H a").format("a")
    console.log("translateTimeMeridian - hourSplitMilitary")
    console.log(hourSplitMilitary)
    console.log("translateTimeMeridian - hourSplit")
    console.log(hourSplit)
    console.log("translateTimeMeridian - meridianSplit")
    console.log(meridianSplit)
    if (toMilitary === true) {
      return hourSplitMilitary
    } else {
      return hourSplit
    } //end toMilitary
  }
  updateSendGroupFieldDirect = (group, prop, val) => {
    const { sendgroupcreate } = this.props

    if (
      group === "deliver" &&
      (prop === "rawhour" || prop === "meridian" || prop === "timezone")
    ) {
      // console.log("moment-format")
      // console.log(
      //   moment(`${val} ${sendgroupcreate.deliver.meridian}`, "H a").format(
      //     "hh:mm a"
      //   )
      // )

      // let utcOffset = moment.tz(moment.utc(), "America/New_York").utcOffset()

      // console.log("[[utcOffset]]")
      // console.log(utcOffset)

      let rawHour = prop === "rawhour" ? val : sendgroupcreate.deliver.rawhour
      let rawMeridian =
        prop === "meridian" ? val : sendgroupcreate.deliver.meridian
      let rawTimezone =
        prop === "timezone" ? val : sendgroupcreate.deliver.timezone

      console.log("[[rawHour]]")
      console.log(rawHour)
      console.log("[[rawMeridian]]")
      console.log(rawMeridian)
      console.log("[[rawTimezone]]")
      console.log(rawTimezone)

      let calcHour = moment(`${rawHour} ${rawMeridian}`, "h a").format("H")

      let mmt = moment.tz(`${rawHour} ${rawMeridian}`, "h a", rawTimezone)

      if (mmt.isDST()) {
        console.log("isDST")
        mmt = mmt.add(1, "hour")
      }

      mmt = mmt.utc()

      console.log("mmt")
      console.log(mmt)

      // Moment at midnight
      var mmtMidnight = mmt.clone().startOf("day")

      // Difference in hours
      var diffHours = mmt.diff(mmtMidnight, "hours")

      console.log("diffHours")
      console.log(diffHours)

      // Difference in minutes
      var diffMinutes = mmt.diff(mmtMidnight, "minutes")

      console.log("diffMinutes")
      console.log(diffMinutes)

      this.props.updateSendGroupFieldDirect(
        group,
        "calchour",
        parseInt(calcHour)
      )
      this.props.updateSendGroupFieldDirect(group, "utchrs", diffHours)
      this.props.updateSendGroupFieldDirect(group, "utcmins", diffMinutes)
      this.props.updateSendGroupFieldDirect(
        group,
        prop,
        prop === "rawhour" ? parseInt(val) : val
      )
    } else {
      this.props.updateSendGroupFieldDirect(group, prop, val)
    }
  }

  render() {
    const { sendgroupcreate } = this.props

    console.log("sendgroupcreate")
    console.log(sendgroupcreate)

    let sendGroupNameError =
      sendgroupcreate &&
      sendgroupcreate.error &&
      sendgroupcreate.error.sendgroupname
        ? true
        : false

    return (
      <>
        <div class="form-group">
          <label for="sendGroupName">Group name</label>
          <input
            type="text"
            class={`form-control ${sendGroupNameError ? "is-invalid" : ""}`}
            name="sendGroupName"
            id="sendGroupName"
            aria-describedby="sendGroupNameHelp"
            placeholder="Enter group name"
            onChange={(e) => {
              this.updateSendGroupFieldDirect(
                "sendgroup",
                "name",
                e.target.value
              )
            }}
            value={
              sendgroupcreate.sendgroup && sendgroupcreate.sendgroup.name
                ? sendgroupcreate.sendgroup.name
                : ""
            }
            required
          />
          <small id="sendGroupNameHelp" class="form-text text-muted">
            This name will be used to identify your send group in the admin
            dashboard
          </small>
          {sendGroupNameError ? (
            <div class="invalid-feedback">
              {sendgroupcreate.error.sendgroupname}
            </div>
          ) : null}
        </div>

        <div class="form-group">
          <label for="sendgroup-time">Send time</label>
          <div class="d-flex flex-row">
            <div class="mr-2">
              <select
                class="form-control"
                // class={`form-control ${
                //   sendgroupcreate.error.criteriaitems &&
                //   sendgroupcreate.error.criteriaitems[i] &&
                //   sendgroupcreate.error.criteriaitems[i].test &&
                //   sendgroupcreate.error.criteriaitems[i].test.data &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j] &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j].dataname
                //     ? "is-invalid"
                //     : ""
                // }`}
                value={sendgroupcreate.deliver.rawhour}
                id="sendgroup-time"
                onChange={(e) => {
                  this.updateSendGroupFieldDirect(
                    "deliver",
                    "rawhour",
                    e.target.value
                  )
                }}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>

                {/* {sendgroupcreate && sendgroupcreate.survey
                ? sendgroupcreate.survey.map((selectitem, k) => (
                    <option
                      selected={
                        sendgroupcreate.criteria[i].test.data[j].dataname ===
                        selectitem.name
                          ? true
                          : false
                      }
                    >
                      {selectitem.title.default}
                    </option>
                  ))
                : null} */}
              </select>
            </div>
            <div class="mr-2">
              <select
                class="form-control"
                // class={`form-control ${
                //   sendgroupcreate.error.criteriaitems &&
                //   sendgroupcreate.error.criteriaitems[i] &&
                //   sendgroupcreate.error.criteriaitems[i].test &&
                //   sendgroupcreate.error.criteriaitems[i].test.data &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j] &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j].dataname
                //     ? "is-invalid"
                //     : ""
                // }`}
                id="sendgroup-time-meridian"
                value={sendgroupcreate.deliver.meridian}
                onChange={(e) => {
                  this.updateSendGroupFieldDirect(
                    "deliver",
                    "meridian",
                    e.target.value
                  )
                }}
              >
                <option value="am" selected>
                  AM
                </option>
                <option value="pm">PM</option>

                {/* {sendgroupcreate && sendgroupcreate.survey
                ? sendgroupcreate.survey.map((selectitem, k) => (
                    <option
                      selected={
                        sendgroupcreate.criteria[i].test.data[j].dataname ===
                        selectitem.name
                          ? true
                          : false
                      }
                    >
                      {selectitem.title.default}
                    </option>
                  ))
                : null} */}
              </select>
            </div>
            <div class="mr-2">
              <select
                class="form-control"
                // class={`form-control ${
                //   sendgroupcreate.error.criteriaitems &&
                //   sendgroupcreate.error.criteriaitems[i] &&
                //   sendgroupcreate.error.criteriaitems[i].test &&
                //   sendgroupcreate.error.criteriaitems[i].test.data &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j] &&
                //   sendgroupcreate.error.criteriaitems[i].test.data[j].dataname
                //     ? "is-invalid"
                //     : ""
                // }`}
                value={sendgroupcreate.deliver.timezone}
                id="sendgroup-time-zone"
                onChange={(e) => {
                  this.updateSendGroupFieldDirect(
                    "deliver",
                    "timezone",
                    e.target.value
                  )
                }}
              >
                {/* <option timeZoneId="1" gmtAdjustment="GMT-12:00" useDaylightTime="0" value="-12">(GMT-12:00) International Date Line West</option> */}
                {/* <option timeZoneId="2" gmtAdjustment="GMT-11:00" useDaylightTime="0" value="-11">(GMT-11:00) Midway Island, Samoa</option> */}
                {moment.tz.names().map((zone, i) => {
                  if (
                    zone === "US/Pacific" ||
                    zone === "US/Mountain" ||
                    zone === "US/Central" ||
                    zone === "US/Eastern"
                  ) {
                    return <option value={zone}>{zone}</option>
                  }
                })}

                {/* {sendgroupcreate && sendgroupcreate.survey
                ? sendgroupcreate.survey.map((selectitem, k) => (
                    <option
                      selected={
                        sendgroupcreate.criteria[i].test.data[j].dataname ===
                        selectitem.name
                          ? true
                          : false
                      }
                    >
                      {selectitem.title.default}
                    </option>
                  ))
                : null} */}
              </select>
            </div>
          </div>
          <small id="screenerNameHelp" class="form-text text-muted">
            The SMS will be sent around this time
          </small>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
