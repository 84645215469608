//React
import React from "react"
import { connect } from "react-redux"

//Gatsby
import { Link } from "gatsby"

//Packages
import { TwitterPicker } from "react-color"
import parse from "url-parse"

//Actions
import {
  checkKeyword,
  updateScreenerForm,
  advanceScreenerForm,
  createScreener,
  updateScreenerFieldDirect,
} from "../../actions/createScreener"
import { copyScreener } from "../../actions/editScreener"

//Components
import Loading from "../loading"
import AppScreenerCreatePosition from "./appScreenerCreatePosition"
import CreateStage1 from "./createStage1"
import CreateStage2 from "./createStage2"
import CreateStage3 from "./createStage3"
import CreateStage4 from "./createStage4"
import LoadingScreener from "./loadingScreener"

//Redux
const mapStateToProps = ({
  campaign,
  templatekeyword,
  templatecreate,
  templateposition,
  isLoadingTemplateCheck,
  isLoadingTemplateCreate,
  isLoading,
}) => {
  return {
    campaign,
    templatekeyword,
    templatecreate,
    templateposition,
    isLoadingTemplateCheck,
    isLoadingTemplateCreate,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateScreenerForm: (prop, val) => dispatch(updateScreenerForm(prop, val)),
  advanceScreenerForm: (pos) => dispatch(advanceScreenerForm(pos)),
  createScreener: (criteria, landing, prompts, screener, survey, id) =>
    dispatch(createScreener(criteria, landing, prompts, screener, survey, id)),
  updateScreenerFieldDirect: (group, prop, val) =>
    dispatch(updateScreenerFieldDirect(group, prop, val)),
  copyScreener: (mode, criteria, landing, prompts, screener, survey) =>
    dispatch(copyScreener(mode, criteria, landing, prompts, screener, survey)),
  checkKeyword: (keyword) => dispatch(checkKeyword(keyword)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showErrorLink: false }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.setState({ templateid: url.query.t })
    const { campaign } = this.props

    console.log("appScreenerCreate - url")
    console.log(url)
    if (!campaign || campaign.length === 0) {
      this.setState({ showErrorLink: true })
    } else if (url && url.query && url.query.t) {
      console.log("appScreenerCreate - url - copyScreener - pre")
      this.copyScreener(url.query.t)
    }
  }

  // checkKeywordAvailability(keyword) {}

  copyScreener = (targetId) => {
    const { campaign } = this.props
    const targetCampaign = campaign.find(({ id }) => id === targetId)
    console.log("appScreenerCreate - targetCampaign")
    console.log(targetCampaign)
    let screenerObj = {
      id: targetCampaign.id,
      name: targetCampaign.title,
    }
    if (targetCampaign.keyword) {
      screenerObj.mode = "response"
      screenerObj.keyword = targetCampaign.keyword
      screenerObj.pushcampaign = [] //need this in case they switch to push mid-flight
    } else if (targetCampaign.pushcampaign) {
      screenerObj.mode = "push"
      screenerObj.pushcampaign = targetCampaign.pushcampaign
    }

    let landingData = targetCampaign.landing.landingdata.find(
      ({ lang }) => lang === "en"
    )
    let landingDataObj = {
      uploadbg: targetCampaign.landing.uploadbg,
      landingdata: [
        {
          lang: "en",
          uploadsubtext: landingData.data.uploadsubtext,
          uploadtext: landingData.data.uploadtext,
        },
      ],
    }

    let logoName = targetCampaign.landing.landingdata.find(
      ({ lang }) => lang === "en"
    )
    logoName = logoName.data.uploadimage
    screenerObj.logoexist = logoName

    let surveyObj = targetCampaign.survey.surveydata.find(
      ({ lang }) => lang === "en"
    )
    surveyObj = surveyObj.data.elements

    let criteriaObj = targetCampaign.criteria.map((criterion, i) => {
      if (criterion.name === "default") {
        return {
          type: "condition",
          name: "default",
          pos: 1,
          title: "default",
          isRequired: true,
          //Yucky: .map() within a .map() below produced an array within an array, so used the .concat.apply to merge these two things. See: https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
          deliver: [].concat.apply(
            [],
            targetCampaign.deliver
              .filter((item) => item.name === "default" && item.lang === "en")
              .map((mapitem, i) =>
                mapitem.data.map((dataitem) => ({
                  lang: "en",
                  title: dataitem.title,
                  text: dataitem.text,
                  displayimage: dataitem.displayimage,
                }))
              )
          ),
          // deliver: [
          //   {
          //     lang: "en",
          //     title: "Thank you",
          //     text:
          //       "Based on your responses, please call us for information on how to proceed",
          //   },
          // ],
          // choices: [{ lang: "en", data: ["", ""] }],
        }
      } else {
        return {
          // deliver: 1,
          type: "condition",
          name: `condition${criterion.pos}`,
          pos: criterion.pos,
          title: criterion.name,
          isRequired: true,
          operator: criterion.test.mode,
          test: {
            data: criterion.test.data,
            value: "",
          },
          //Yucky: .map() within a .map() below produced an array within an array, so used the .concat.apply to merge these two things. See: https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
          deliver: [].concat.apply(
            [],
            targetCampaign.deliver
              .filter(
                (item) => item.name === criterion.name && item.lang === "en"
              )
              .map((mapitem, i) =>
                mapitem.data.map((dataitem) => ({
                  lang: "en",
                  title: dataitem.title,
                  text: dataitem.text,
                  displayimage: dataitem.displayimage,
                }))
              )
          ),
        }
      }
    })

    this.props.copyScreener(
      "copy",
      criteriaObj,
      landingDataObj,
      targetCampaign.prompts,
      screenerObj,
      surveyObj
    )
  }

  createScreener = (criteria, landing, prompts, screener, survey) => {
    const url = parse(window.location.href, true)
    const existingId = url && url.query && url.query.t ? url.query.t : null

    this.props.createScreener(
      criteria,
      landing,
      prompts,
      screener,
      survey,
      existingId
    )
  }

  updateScreenerFieldDirect = (group, prop, val) => {
    this.props.updateScreenerFieldDirect(group, prop, val)
  }
  advanceScreenerForm(pos) {
    const { templatecreate, templatekeyword } = this.props

    if (pos === 2) {
      if (!templatecreate.screener.name) {
        this.updateScreenerFieldDirect(
          "error",
          "screenername",
          "Please include a name for this screener"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "screenername", false)
      }

      if (templatecreate.screener.mode === "response") {
        if (
          templatecreate.screener.mode === "response" &&
          !templatecreate.screener.keyword
        ) {
          this.updateScreenerFieldDirect(
            "error",
            "screenerkeyword",
            "Please include a keyword for this screener"
          )
          return
          // } else if (
          //   templatecreate.screener.mode === "response" &&
          //   templatekeyword === false
          // ) {
          //   this.updateScreenerFieldDirect(
          //     "error",
          //     "screenerkeyword",
          //     "Please select a different keyword"
          //   )
          //   return
        } else {
          //check keyword avail NOW AT BOTTOM
        }
      } else {
        this.updateScreenerFieldDirect("error", "screenerkeyword", false)
      }

      if (templatecreate.screener.mode === "push") {
        if (
          templatecreate.screener.mode === "push" &&
          templatecreate.screener.pushcampaign.length === 0
        ) {
          this.updateScreenerFieldDirect(
            "error",
            "screenerpushcampaignlength",
            "Please select at least one push campaign"
          )
          return
        } else {
          // this.updateScreenerFieldDirect("error", "screenerpushcampaignlength", false)
          let pushcampaignContainsError = false
          let pushcampaignError = []
          templatecreate.screener.pushcampaign.forEach((item, i) => {
            let isErrorPushCampaignItem = false
            if (!item) {
              isErrorPushCampaignItem = true
              pushcampaignContainsError = true
            } else {
              isErrorPushCampaignItem = false
            }

            pushcampaignError.push(isErrorPushCampaignItem)
          }) //end templatecreate.survey foreach

          this.updateScreenerFieldDirect(
            "error",
            "screenerpushcampaignitems",
            pushcampaignError
          )
          this.updateScreenerFieldDirect(
            "error",
            "screenerpushcampaignlength",
            false
          )

          if (pushcampaignContainsError === true) {
            this.updateScreenerFieldDirect(
              "error",
              "screenerpushcampaignitemserror",
              "Please select a send group for all drop down fields"
            )
            return
          } else {
            this.updateScreenerFieldDirect(
              "error",
              "screenerpushcampaignitemserror",
              false
            )
          }
        } //end if push & length
      } else {
        //then is not push
        this.updateScreenerFieldDirect(
          "error",
          "screenerpushcampaignlength",
          false
        )
        this.updateScreenerFieldDirect(
          "error",
          "screenerpushcampaignitems",
          false
        )
      } //end if/else push

      if (!templatecreate.prompts[0].data[0].text) {
        this.updateScreenerFieldDirect(
          "error",
          "prompttext",
          "Please include text for the welcome SMS"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "prompttext", false)
      }
    } else if (pos === 3) {
      if (
        templatecreate.error.imagewidth ||
        templatecreate.error.imagesize ||
        templatecreate.error.imageextension
      ) {
        return
      }

      if (!templatecreate.screener.logo && !templatecreate.screener.logoexist) {
        this.updateScreenerFieldDirect(
          "error",
          "screenerlogo",
          "Please upload a logo"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "screenerlogo", false)
      }

      if (!templatecreate.landing.landingdata[0].uploadtext) {
        this.updateScreenerFieldDirect(
          "error",
          "uploadtext",
          "Please include a landing page title"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "uploadtext", false)
      }
      if (!templatecreate.landing.landingdata[0].uploadsubtext) {
        this.updateScreenerFieldDirect(
          "error",
          "uploadsubtext",
          "Please include landing page text"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "uploadsubtext", false)
      }
    } else if (pos === 4) {
      if (templatecreate.survey.length === 0) {
        this.updateScreenerFieldDirect(
          "error",
          "surveylength",
          "Please include at least one question in the screener"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "surveylength", false)
      }

      let surveyContainsError = false
      let surveyError = []
      templatecreate.survey.forEach((item, i) => {
        let questionError = {}
        if (item.type === "radiogroup") {
          if (!item.title.default) {
            questionError.title = true
            surveyContainsError = true
          } else {
            questionError.title = false
          }

          let choicesError = []
          item.choices.forEach((choice, j) => {
            if (!choice.text.default) {
              choicesError.push({ title: true })
              surveyContainsError = true
            } else {
              choicesError.push({ title: false })
            }
          }) //end sub foreach

          questionError.choices = choicesError
        } //end radiogroup
        surveyError.push(questionError)
      }) //end templatecreate.survey foreach

      this.updateScreenerFieldDirect("error", "surveyitems", surveyError)

      if (surveyContainsError === true) {
        this.updateScreenerFieldDirect(
          "error",
          "surveyerror",
          "Please fix the errors in the screener"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "surveyerror", false)
      }
    } else if (pos === 5) {
      let criteriaContainsError = false
      let criteriaError = []
      templatecreate.criteria.forEach((item, i) => {
        let criteriaItemError = { title: false }
        if (item.name === "default") {
          criteriaItemError.title = false
          criteriaItemError.deliver = [{ title: false, text: false }]
          criteriaItemError.test = {
            data: [{ dataname: false, dataval: false }],
          }
        } else {
          if (!item.title) {
            criteriaItemError.title = true
            criteriaContainsError = true
          }

          let deliverError = []
          let deliverItemError = { title: false, text: false }
          if (!item.deliver[0].title) {
            deliverItemError.title = true
            criteriaContainsError = true
          }
          if (!item.deliver[0].text) {
            deliverItemError.text = true
            criteriaContainsError = true
          }
          deliverError.push(deliverItemError)
          criteriaItemError.deliver = deliverError

          let testError = []
          item.test.data.forEach((testitem, j) => {
            let testItemError = { dataname: false, dataval: false }
            if (!testitem.dataname) {
              testItemError.dataname = true
              criteriaContainsError = true
            }
            if (!testitem.dataval) {
              testItemError.dataval = true
              criteriaContainsError = true
            }
            testError.push(testItemError)
          }) //end sub foreach
          criteriaItemError.test = { data: testError }
        } //end if/else default
        criteriaError.push(criteriaItemError)
      }) //end templatecreate.criteria foreach

      this.updateScreenerFieldDirect("error", "criteriaitems", criteriaError)

      if (criteriaContainsError === true) {
        this.updateScreenerFieldDirect(
          "error",
          "criteriaerror",
          "Please fix the errors in the evaluation criteria"
        )
        return
      } else {
        this.updateScreenerFieldDirect("error", "criteriaerror", false)
        this.createScreener(
          templatecreate.criteria,
          templatecreate.landing,
          templatecreate.prompts,
          templatecreate.screener,
          templatecreate.survey
        )
      }
    } //end if pos

    ///keyword avail
    if (
      pos === 2 &&
      templatecreate.screener.mode === "response" &&
      templatecreate.inputmode === "edit" &&
      templatecreate.screener.keyword === templatecreate.screener.keywordorig
    ) {
      console.log("***keywords the same, skipping check")
      this.props.advanceScreenerForm(pos)
    } else if (
      pos === 2 &&
      templatecreate.screener.mode === "response" &&
      templatecreate.screener.keyword
    ) {
      console.log("**starting this.props.checkKeyword")
      this.props
        .checkKeyword(templatecreate.screener.keyword)
        .then((result) => {
          console.log("***** checkKeywordAvailability - result")
          console.log(result)
          //result === true means that the keyword is available
          if (result === true) {
            this.updateScreenerFieldDirect("error", "screenerkeyword", false)
            this.props.advanceScreenerForm(pos)
          } else {
            this.updateScreenerFieldDirect(
              "error",
              "screenerkeyword",
              "Please select a different keyword"
            )
            return
          }
        })
    } else {
      this.props.advanceScreenerForm(pos)
    }
  }

  render() {
    const {
      templateposition,
      templatecreate,
      isLoading,
      isLoadingTemplateCreate,
    } = this.props
    const { templateid } = this.state

    return (
      <div class="app-screener-create-container">
        {/* <AppScreenerCreatePosition /> */}
        <form class="p-2 needs-validation" novalidate>
          {templateposition === 1 ? <CreateStage1 /> : null}
          {templateposition === 2 ? <CreateStage2 /> : null}
          {templateposition === 3 ? <CreateStage3 /> : null}
          {templateposition === 4 ? <CreateStage4 /> : null}
          {isLoadingTemplateCreate ? <LoadingScreener /> : null}
          <div class="mt-5 py-3 border-top-1-gray-6 d-flex flex-row align-items-center justify-content-end">
            {templateposition > 1 ? (
              <button
                type="button"
                class="btn btn-screener-create-back white-1 mx-2 px-2"
                onClick={() => {
                  this.advanceScreenerForm(templateposition - 1)
                }}
              >
                &lsaquo; Back
              </button>
            ) : null}
            {templateposition >= 1 && templateposition < 4 ? (
              <button
                type="button"
                class="btn btn-dkblue mx-2"
                onClick={() => {
                  this.advanceScreenerForm(templateposition + 1)
                }}
              >
                Next &rsaquo;
              </button>
            ) : null}
            {templateposition === 4 ? (
              <button
                type="button"
                class="btn btn-dkblue mx-2"
                onClick={(e) => {
                  this.advanceScreenerForm(templateposition + 1)
                }}
              >
                Finish
              </button>
            ) : null}
          </div>
        </form>
      </div>
    )
  }
}

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
